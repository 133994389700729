/* eslint-disable react-hooks/exhaustive-deps */
import "react-spring-bottom-sheet/dist/style.css";
import "react-toastify/dist/ReactToastify.css";
import "../../../constants/Toast.css";

import { DateIcon, EmergencyFill } from "../../../constants/SvgCreator.js";
import React, { Fragment, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Aside from "../../Styles.js";
import Axios from "axios";
import BadgeAvatars from "../../../components/Avatar";
import { BottomSheet } from "react-spring-bottom-sheet";
import Carousel from "react-elastic-carousel";
import HELP from "../../../assets/image/HELP.svg";
import Heart from "../../../assets/image/Heart.svg";
import InitialLoad from "../../../components/Loading";
import OfferView from "../../../components/OfferView";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { toasty } from "../../../constants/Toast";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Details() {
  let location = useLocation();
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;

  const [request, setRequest] = useState("");
  const [user, setUser] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageSelect, setImageSelect] = useState("");
  const [showCompliance, setShowCompliance] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [OfferStatus, setOfferStatus] = useState(false);
  const [offers, setOffers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const [rate, setRate] = useState(2);
  const [offerId, setOfferId] = useState("");

  console.log("===>", location.state.myProp);
  React.useEffect(() => {
    // effect
    getHomeData();
    if (location.state.from === "Request") {
      getOffers();
    }
    return () => {
      // cleanup
    };
  }, []);

  let history = useHistory();
  const [open, setOpen] = useState(false);
  const onBack = () => {
    history.goBack();
  };

  function onDismiss() {
    setOpen(false);
    // setTimeout(() => {
    //   setImageModal(false);
    // }, 1000);
  }
  function onDismissImage() {
    setImageModal(false);
    // setTimeout(() => {
    //   setImageModal(false);
    // }, 1000);
  }

  const getHomeData = () => {
    // let bearer = `Bearer ${User.token}`;
    Axios.get(`/requests/${location.state.myProp.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.data.successful) {
          console.log(res.data.data);
          setRequest(res.data.data.request);
          setUser(res.data.data.user_request);
          setShowCompliance(res.data.data.request.userOffered);
          if (location.state.from !== "Request") {
            setPageLoading(false);
          }
        } else {
          // console.log('error');
        }
      })
      .catch((err) => {
        console.log(err);
        toasty("مشکلی پیش آمده", "error");
      });
  };
  const acceptedRequest = () => {
    setShowCompliance(true);
    Axios.post(`/request/accept/${location.state.myProp.id}`, null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data.successful) {
          toasty("اجابت شد، قبول باشه.", "success");
          // setIsLoading(true);
          // setVisibleMessage(true);
          // setButtonType(true);
        } else {
          console.log("error");
          setShowCompliance(false);
        }
      })
      .catch((err) => {
        setShowCompliance(false);
        toasty("مشکلی پیش آمده", "error");

        console.log(err);
      });
  };

  const getOffers = () => {
    Axios.get(`/request/offers/${location.state.myProp.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.data.successful) {
          console.log(res.data.data);
          setOfferStatus(res.data.data.request.status);
          setOffers(res.data.data.offers);
          setPageLoading(false);
        } else {
          // console.log('error');
        }
      })
      .catch((err) => {
        console.log(err);
        toasty("مشکلی پیش آمده", "error");
      });
  };

  const renderBtn = () => {
    if (showCompliance === false && location.state.from === "details") {
      return (
        <Accepted onClick={() => acceptedRequest()}>
          <p>اجابت</p>
        </Accepted>
      );
    } else if (location.state.from === "Request") {
      return (
        <Accepted edit onClick={() => editPost()}>
          <p>ویرایش</p>
        </Accepted>
      );
    }
  };

  const editPost = () => {
    history.push("/Registration", {
      myProp: request,
      from: "Edit",
      foo: "bar",
    });
  };

  const goChat = () => {
    history.push("/Chat", {
      data: user,
      foo: "bar",
    });
  };

  const renderView = () => {
    switch (location.state.from) {
      case "Request":
        return (
          <OfferView
            showModal={(item) => {
              setUser(item);
              setImageModal(false);
              setOpen(true);
            }}
            completeRequest={(item, type) => {
              !type ? completeRequest(item, type) : showModalView(item);
            }}
            status={OfferStatus}
            data={offers}
          />
        );
      case "offer":
        return (
          <Category>
            <HeartImage />
            <SubCat>
              <Title>
                {location.state.myProp.status
                  ? "اجابت انجام شد"
                  : "در انتظار تایید ثبت کننده نیاز"}
              </Title>
            </SubCat>
          </Category>
        );

      default:
        break;
    }
  };

  const showModalView = (item) => {
    setOfferId(item.offer_id);
    setShowModal(true);
  };

  const onPress = (score) => {
    completeRequest(offerId, true, score);
    setShowModal(false);
  };

  const completeRequest = (item, type, score) => {
    console.log(item, type);
    let params = {};
    if (type === false) {
      params = {
        offer_id: item.offer_id,
      };
    } else {
      params = {
        offer_id: item,
        score: score,
      };
    }
    Axios.post(`/request/complete/${location.state.myProp.id}`, null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      params: params,
    })
      .then((res) => {
        // console.log(res.data)
        if (res.data.successful) {
          console.log("===>", res.data);
          toasty(res.data.message, "success");
          getOffers();
          // setVisibleRate(false);
        } else {
          // modalEvent(res.data.message);
          console.log(res.data.message);
          toasty(res.data.message, "warn");
        }
      })
      .catch((err) => {
        console.log(err);
        toasty("مشکلی پیش آمده", "error");
      });
  };

  return (
    <Aside>
      <Header>
        <div>
          <BackBtn onClick={() => onBack()}>
            <ArrowBackIosIcon fontSize="small" />
          </BackBtn>
          <HeaderTitle>جزییات ثبت نیازمندی‌ها</HeaderTitle>
        </div>
      </Header>
      <Main>
        <InitialLoad loading={pageLoading}>
          <Content>
            <ReqHeader>
              <DateView>
                <DateIcon style={{ fontSize: 14 }} />
                <Date>{request.created_at}</Date>
              </DateView>
              <Title>{request.title}</Title>
              <DateView>
                <Date left={true}>فوریت</Date>
                <EmergencyFill
                  style={{
                    fontSize: 14,
                    color: request.urgent === 0 ? "#cecece" : "#E9F257",
                  }}
                />
              </DateView>
            </ReqHeader>

            {renderView()}
            <Category>
              <HelpImage />
              <SubCat>
                <div>
                  <CatText2>
                    دسته بندی : {request.ability} {`>`} {request.sub_ability}{" "}
                  </CatText2>
                </div>
                <State>
                  <CatText>استان :{request.state}</CatText>
                  <CatText>شهر : {request.city}</CatText>
                </State>
              </SubCat>
            </Category>
            <DescriptionTitle>داستان ما</DescriptionTitle>
            <Description>{request.story}</Description>
            {request.image?.length > 0 ? (
              <Fragment>
                <DescriptionTitle>عکس‌ها</DescriptionTitle>
                <ImageCarousel>
                  <Carousel
                    isRTL
                    showArrows={false}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                    pagination={false}
                    itemsToScroll={3}
                    itemsToShow={3}
                  >
                    {request.image.map((item, index) => (
                      <Item
                        onClick={() => {
                          setImageSelect(item.url);
                          setImageModal(true);
                          // setOpen(true);
                        }}
                        key={index}
                        src={item.url}
                      />
                    ))}
                  </Carousel>
                </ImageCarousel>
              </Fragment>
            ) : undefined}
            <BtnViews>
              {location.state.from !== "Request" ? (
                <Communication
                  onClick={() => {
                    setImageModal(false);
                    setOpen(true);
                  }}
                >
                  <p>ارتباط با ثبت‌کننده نیاز</p>
                </Communication>
              ) : null}
              {renderBtn()}
            </BtnViews>

            {/* <a href="tel:15555551235">+1 (555) 555-1234</a> */}
          </Content>
        </InitialLoad>
      </Main>
      <BottomSheet onDismiss={onDismiss} open={open}>
        <SheetContent>
          <BadgeAvatars
            name={user.second_name}
            image={user.image}
            newMessage={false}
          />

          <UserName>{user.second_name}</UserName>
          <ActionView>
            <Button onClick={() => goChat()}>
              <p>گفت‌و‌گو</p>
            </Button>
            <Button>
              <a href={`tel:${user.phone}`}>تماس</a>
            </Button>
          </ActionView>
        </SheetContent>
      </BottomSheet>
      <BottomSheet onDismiss={onDismissImage} open={imageModal}>
        <FullImage src={imageSelect} />
      </BottomSheet>
      <ToastContainer rtl />

      {showModal ? (
        <Modal>
          <ModalContent>
            <RateTitle>
              'آیا نیازمندی اجابت شده به انجام رسیده است ،در این صورت انجام آنرا
              چگونه ارزیابی می کنید؟'
            </RateTitle>
            <Row>
              <RteBtn
                onClick={() => {
                  onPress(2);
                }}
              >
                ضیف
              </RteBtn>
              <RteBtn
                onClick={() => {
                  onPress(3);
                }}
              >
                متوسط
              </RteBtn>
              <RteBtn
                onClick={() => {
                  onPress(4);
                }}
              >
                عالی
              </RteBtn>
            </Row>
          </ModalContent>
        </Modal>
      ) : null}
    </Aside>
  );
}

const Header = styled.div`
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;
  /* identical to box height */
  text-align: center;
  color: #009544;
`;

const BackBtn = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  align-items: center;
  justify-content: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  scroll-behavior: smooth;
  /* margin-top: 50px; */
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 65px;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;

const Content = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 97%;
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 20px;
  padding-bottom: 10px;
`;

const ReqHeader = styled.div`
  flex-direction: row-reverse;
  width: 95%;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 15px;
  /* background-color: #256; */
`;

const Date = styled.p`
  direction: rtl;
  font-family: "Vista";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  margin-right: 5px;
  color: #6a6a6a;
  margin-left: ${(props) => (props.left ? "5px" : 0)};
`;

const Title = styled.p`
  direction: rtl;
  text-align: center;
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #009544;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const DateView = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const Category = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 95%;
  align-items: center;
  margin-top: 13px;
  /* border: 1px solid #DEDEDE;
box-sizing: border-box;
border-radius: 20px; */
`;
const SubCat = styled.div`
  /* display: flex; */
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 10px;

  & > div {
    display: flex;
    flex-direction: row-reverse;
    /* height: 17px; */
  }
`;

const HelpImage = styled.div`
  background-image: url(${HELP});
  width: 47px;
  height: 48px;
`;

const HeartImage = styled.div`
  background-image: url(${Heart});
  width: 29.9px;
  height: 22.75px;
  margin-right: 15px;
  background-repeat: no-repeat;
`;

const CatText = styled.a`
  direction: rtl;
  font-family: "Vista";
  font-size: 10px;
  line-height: 17px;
  margin-right: 5px;
  color: #6a6a6a;
`;
const CatText2 = styled.a`
  width: 70%;
  direction: rtl;
  font-family: "Vista";
  font-size: 10px;
  line-height: 17px;
  margin-right: 5px;
  color: #6a6a6a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const State = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 10px;
  top: 5px;
`;

const Description = styled.p`
  min-width: 80%;
  padding-right: 15px;
  padding-left: 15px;

  font-family: "Vista";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  direction: rtl;
  text-align: justify;
  /* or 83% */
  color: #585858;
  margin-top: 5px;
`;

const DescriptionTitle = styled.p`
  text-align: right;
  padding-right: 15px;
  width: 95%;
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #009544;
  height: 15px;
`;

const ImageCarousel = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 20px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Communication = styled.div`
  /* width: 235px; */
  height: 25px;
  background: #009544;
  border: 1px solid #009544;
  box-sizing: border-box;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
  & > p {
    font-family: "Vista";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;

    color: #ffffff;
  }
`;

const Accepted = styled.div`
  width: 106px;
  height: 31px;
  border: 1px solid #009544;
  box-sizing: border-box;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  background-color: ${(props) => (props.edit ? "#009544" : "#fff")};
  & > p {
    font-family: "Vista";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: ${(props) => (props.edit ? "#fff" : "#009544")};
  }
`;

const SheetContent = styled.div`
  width: 100%;
  height: 35vh;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 15px;
`;

const Modal = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 40vh;
  align-items: center;
  justify-content: center;
`;
const ModalContent = styled.div`
  width: 95%;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
  border-radius: 20px;
  transition: visibility 0s, opacity 0.5s linear;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
`;

const UserName = styled.p`
  height: 24px;
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #009544;
`;

const ActionView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 10px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 122px;
  height: 31px;
  border: 1px solid #009544;
  box-sizing: border-box;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  & > p {
    height: 27px;
    font-family: "Vista";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #f55d3e;
  }
  & > a {
    height: 27px;
    font-family: "Vista";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #f55d3e;
    text-decoration: none;
  }
`;

const FullImage = styled.div`
  width: 100%;
  height: 50vh;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const BtnViews = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const RateTitle = styled.p`
  direction: rtl;
  text-align: center;
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #585858;
`;

const Row = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  height: 24px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
`;

const RteBtn = styled.div`
  width: 28%;
  margin: auto;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 10px; */
  /* identical to box height, or 71% */
  text-align: center;
  color: #009544;
`;
