import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const badgeStyle = makeStyles((theme) => ({
  root: {
  
    "& > *": {
      margin: theme.spacing(1),
      fontFamily:'Vista',
      backgroundColor: "#F55D3E",
      color: "white",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: `0px 0px 4px rgba(0, 0, 0, 0.25)`,
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    boxShadow: `0px 0px 4px rgba(0, 0, 0, 0.25)`,
  },
}));

export default function BadgeAvatars({
  newMessage,
  name,
  image,
  size = "large",
  count,
}) {
  const classes = useStyles();
  const badgeClasses = badgeStyle();

  return (
    <div className={classes.root}>
      {newMessage ? (
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className={badgeClasses.root}
          badgeContent={count}
          color="secondary"
        >
          <Avatar
            className={size === "large" ? classes.large : classes.small}
            alt={name}
            src={image}
          />
        </Badge>
      ) : (
        <Avatar
          className={size === "large" ? classes.large : classes.small}
          alt={name}
          src={image}
        />
      )}
    </div>
  );
}
