import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Aside from "../../Styles";
import Axios from "axios";
import ChipsArray from "../../../components/Chip";
import Grid from "../../../components/Grid";
import HomeSearch from "../../../components/HomeSearch";
import InitialLoad from "../../../components/Loading";
import { addCategory } from "../../store/actions/Categories_action";
import styled from "styled-components";

export default function SubAbilityPage() {
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  const dispatch = useDispatch();

  let history = useHistory();
  let location = useLocation();
  const abilityText = location.state.title;
  const abilityId = location.state.id;
  const [searchValue, setSearchValue] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [tempCategoryData, setTempCategoryData] = useState([]);

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    // effect
    getDataApi();
    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataApi = () => {
    Axios.get(`/subabiliyt/ability/${abilityId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.data.successful) {
          setPageLoading(false);
          // console.log(res.data.data);
          setCategoryData(res.data.data);
          setTempCategoryData(res.data.data);
        } else {
          console.log("error");
          setPageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };

  const callSearch = (text) => {
    let str = text.trim();
    if (str.length === 0) {
      setCategoryData(tempCategoryData);
    } else {
      const newData = categoryData.filter((item) => {
        const itemData = `${item.title.toUpperCase()}`;
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setCategoryData(newData);
    }
  };

  const selectedData = (item) => {
    let temp = {...item, ability_id: abilityId};
    dispatch(addCategory(temp));
  };

  return (
    <Aside>
      <Header>
        <div>
          <BackBtn onClick={() => onBack()}>
            <ArrowBackIosIcon fontSize="small" />
          </BackBtn>
          <HeaderTitle>زیرشاخه‌ها</HeaderTitle>
        </div>
      </Header>
      <InitialLoad loading={pageLoading}>
        <Main>
          <HomeSearch
            type="none"
            label={"ﺟﺴﺖ‌و‌ﺟﻮ در توانمندی‌ها"}
            value={searchValue}
            onChange={(val) => {
              callSearch(val);
              setSearchValue(val);
            }}
          />
          <ChipsArray />
          <Title>{abilityText}</Title>
          <Content>
            <Container>
              <Grid onPress={(item)=>selectedData(item)} data={categoryData} />
            </Container>
          </Content>
        </Main>
      </InitialLoad>
    </Aside>
  );
}

const Header = styled.div`
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;
  /* identical to box height */
  text-align: center;
  color: #009544;
`;

const BackBtn = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  align-items: center;
  justify-content: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* margin-top: 56px; */
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;

const Title = styled.div`
  direction: rtl;
  width: 85%;
  height: 20px;
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  margin-top: 15px;
  color: #6a6a6a;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
