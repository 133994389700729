import { Link, useLocation } from "react-router-dom";
import React, { Fragment } from "react";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";

import Add from "@material-ui/icons/Add";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Home from "@material-ui/icons/Home";
import Person from "@material-ui/icons/Person";

const useStyles = makeStyles({
  root: {
    width: "100%",
    boxShadow: "0 3px 5px 2px rgba(0, 149, 68, .3)",
  },
});

const navTheme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(0, 149, 68, 1)",
    },
    text: {
      secondary: "rgba(111, 111, 111, 1)",
    },
  },
  typography: {
    fontFamily: ["Vista"].join(","),
  },
});

export default function SimpleBottomNavigation({ showHome }) {
  let location = useLocation();
  const classes = useStyles();

  const [value, setValue] = React.useState("");
  const [hideNavigation, setHideNavigation] = React.useState(true);
  React.useEffect(() => {
    // effect
    setValue(location.pathname);
    TabSelect(location.pathname);
    return () => {
      // cleanup
    };
  }, [location]);

  showHome(location.pathname !== "/" ? true : false);
  const show = "/, /HomePage, /Details, , /Registration , /Profile";
  const TabSelect = (item) => {
    var Home = " /, /HomePage, /Details";
    var Register = "Registration";
    var Profile = "Profile";
    if (Home.includes(item)) {
      setValue("/HomePage");
    }
    if (Register.includes(item)) {
      setValue("/Registration");
    }
    if (Profile.includes(item)) {
      setValue("/Profile");
    }

    if (show.includes(item)) {
      setHideNavigation(true);
    } else {
      setHideNavigation(false);
    }
  };

  return (
    <Fragment>
      {hideNavigation ? (
        <ThemeProvider theme={navTheme}>
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction
              component={Link}
              to={"/HomePage"}
              value={"/HomePage"}
              label="خانه"
              icon={<Home />}
            />
            <BottomNavigationAction
              component={Link}
              to={"/Registration"}
              value={"/Registration"}
              label="ثبت نیازمندی"
              icon={<Add />}
            />
            <BottomNavigationAction
              component={Link}
              to={"/Profile"}
              value={"/Profile"}
              label="پروفایل"
              icon={<Person />}
            />
          </BottomNavigation>
        </ThemeProvider>
      ) : null}
    </Fragment>
  );
}
