// import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import { clearCategory } from "../pages/store/actions/Categories_action";
import { logout } from "../pages/store/actions/user_action";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export default function TemporaryDrawer({ drawer, onCloseDrawer , showHelp }) {
  const dispatch =useDispatch()
  let history = useHistory();

  const goToMessageList = () => {
    history.push("/ChatList");
  };

  const ExitToApp =()=>{
    dispatch(logout() , clearCategory())
    history.push("/Login");
    
  }
  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} open={drawer} onClose={() => onCloseDrawer()}>
          <DrawerList>
            <ListItem onClick={() => goToMessageList()}>پیام‌ها</ListItem>
            <ListItem onClick ={()=>showHelp()} >راهنما</ListItem>
            <ListItem onClick ={()=>ExitToApp() }isExit>خروج</ListItem>
          </DrawerList>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const DrawerList = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-top: 25px;
  justify-content: center;
  align-items: center;
`;

const ListItem = styled.div`
  width: 90%;
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  height: 34px;
  border-bottom: 1px solid #d1d1d1;
  margin-top: 14px;

  color: #6a6a6a;
`;
