import { Fragment } from "react";
import { ImpulseSpinner } from "react-spinners-kit";
import React from "react";
import styled from "styled-components";

function InitialLoad({ children, loading }) {
  return (
    <Fragment>
      {loading ? (
        <Container>
          <ImpulseSpinner
            size={50}
            frontColor="#009544"
            backColor={"#cecece"}
          />
        </Container>
      ) : (
        children
      )}
    </Fragment>
  );
}

export default InitialLoad;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 50vh;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
