import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";

import AbilityPage from "./pages/tabPage/profile/AbilityPage";
import AllHistory from "./pages/tabPage/profile/AllHistory";
import Axios from 'axios'
import Chat from "./pages/tabPage/profile/Chat";
import ChatList from "./pages/tabPage/profile/ChatList";
import Details from "./pages/tabPage/home/Details";
// import Home from "./pages/Route/Home";
import HomePage from "./pages/tab/HomePage";
import Login from "./pages/tab/Login";
import Otp from "./pages/tab/Otp";
import Profile from "./pages/tab/Profile";
import React from "react";
import Registration from "./pages/tab/Registration";
import RouteWithSubRoutes from "./pages/Route/RouteWithSubRoutes";
import SimpleBottomNavigation from "./components/BottomNavigation";
import SubAbilityPage from "./pages/tabPage/profile/SubAbilityPage";
import styled from "styled-components";
import { useSelector } from "react-redux";

Axios.defaults.baseURL = 'https://kheirnetwork.com/api';
const routes = [
  {
    path: "/Login",
    component: Login,
  },
  {
    path: "/Otp",
    component: Otp,
  },
  {
    path: "/HomePage",
    component: HomePage,
  },
  {
    path: "/Registration",
    component: Registration,
  },
  {
    path: "/Profile",
    component: Profile,
  },
  {
    path: "/Details",
    component: Details,
  },
  {
    path: "/Ability",
    component: AbilityPage,
  },
  {
    path: "/SubAbility",
    component: SubAbilityPage,
  },
  {
    path: "/AllHistory",
    component: AllHistory,
  },
  {
    path: "/ChatList",
    component: ChatList,
  },
  {
    path: "/Chat",
    component: Chat,
  },
];

function App({ location }) {
  const [state, setState] = React.useState(false);
  const user = useSelector((state) => state.User);
  return (
    <Router>
      {user.isLogin === false ? <Redirect to="/login" /> : null}
      <Warper>
        {!state ? <HomePage /> : null}
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
        <Navigator>
          <SimpleBottomNavigation
            location={location}
            showHome={(event) => setState(event)}
          />
        </Navigator>
      </Warper>
    </Router>
  );
}

export default App;
const Warper = styled.div`
  margin-top: 0px;
  top: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Navigator = styled.div`
  width: 100%;
  max-width: 768px;
  position: fixed;
  bottom: 0;
`;
