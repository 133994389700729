//important for getting nice style.

import Button from "../../../components/Button";
import Cancel from "../../../assets/image/cancel.svg";
import ImageUploading from "react-images-uploading";
import React from "react";
import UploadImage from "../../../assets/image/Upload.svg";
import styled from "styled-components";

function StepThree({
  onPressNext,
  onSubmitEdit,
  images,
  setImages,
  serverImage,
  edit,
  onServerImageRemove,
  btnLoading,
}) {
  const maxNumber = 6;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  // console.log("===>", serverImage);
  return (
    <Content>
      {edit ? (
        <ImageWrapper>
          {serverImage.map((image, index) => (
            <ImageView
              onClick={() => onServerImageRemove(image.id)}
              key={index}
              src={image.url}
            >
              <CancelBtn />
            </ImageView>
          ))}
        </ImageWrapper>
      ) : null}
      <Title>لطفا اگر عکسی دارید، آن را به اشتراک بگذارید.</Title>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "jpeg", "png"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <ViewWrapper>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ImageWrapper>
                {imageList.map((image, index) => (
                  <ImageView
                    onClick={() => onImageRemove(index)}
                    key={index}
                    src={image.data_url}
                  >
                    <CancelBtn />
                  </ImageView>
                ))}
              </ImageWrapper>
            </div>
            <UploadBtn
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            ></UploadBtn>
          </ViewWrapper>
        )}
      </ImageUploading>
      <Button
        loading={btnLoading}
        active={true}
        text={edit ? "ثبت ویرایش" : "ثبت"}
        onPress={() => (edit ? onSubmitEdit() : onPressNext())}
      />
    </Content>
  );
}

export default StepThree;
const Content = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 97%;
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
`;

const Title = styled.div`
  direction: rtl;
  display: flex;
  font-family: "Vista";
  font-size: 14px;
  text-align: center;
  color: #828282;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  border: 1px dashed #a5a5a5;
  box-sizing: border-box;
  border-radius: 20px;
  align-items: center;
  padding-top: 15px;
  margin-top: 15px;
`;

const ImageWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const UploadBtn = styled.div`
  background-image: url(${UploadImage});
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 15px;
`;

const ImageView = styled.div`
  direction: rtl;
  width: 85px;
  height: 85px;
  border-radius: 20px;
  background-color: whitesmoke;
  margin: 5px;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
`;

const CancelBtn = styled.div`
  width: 15px;
  height: 15px;
  background-image: url(${Cancel});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 8px;
  margin-right: 8px;
`;
