/* eslint-disable react-hooks/exhaustive-deps */
import { DateIcon, EmergencyFill } from "../../constants/SvgCreator";

import Axios from "axios";
import HomeSearch from "../../components/HomeSearch";
import InfiniteScroll from "react-infinite-scroll-component";
import React from "react";
import handHeart from "../../assets/image/handHeart.svg";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function HomePage() {
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  const timerRef = React.useRef(null);
  let history = useHistory();
  const [value, setValue] = React.useState("");
  const [data, setData] = React.useState([]);
  const [catData, setCatData] = React.useState([]);
  const [selectAbility, setSelectAbility] = React.useState("");
  const [loadingPage, setLoadingPage] = React.useState(true);

  const onPressItem = (item) => {
    // history.push(`/Details/:${item.id}`);
    history.push("/Details", {
      myProp: item,
      from: "details",
      foo: "bar",
    });
  };

  React.useEffect(() => {
    // effect
    fetchData();
    abilityRandom();
    return () => {
      // cleanup
    };
  }, []);

  const fetchData = async (isClear) => {
    console.log("fetch");
    setLoadingPage(true);
    Axios.get("/requests", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      params: {
        // q: item,
        // page: pageCurrent,
      },
    })
      .then((res) => {
        // console.log("54545454", res.data.data.pagination);
        if (res.data.successful) {
          // console.log(res.data.data.requests);
          // setPagination(true);
          if (isClear) {
            setData(res.data.data.requests);
          } else {
            setData(data.concat(res.data.data.requests));
          }
          setLoadingPage(false);
          // setData((prev)=>[...prev , res.data.data.requests])

          // setPagination(res.data.data.pagination.total_pages);
          // setDataLoading(false);
        } else {
          console.log("error");
          setLoadingPage(false);
          // toast(res.data.message);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingPage(false);
        // setIsLoading(false);
      });
  };

  const abilityRandom = () => {
    Axios.get("/subabilities/random", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        //  console.log(res.data.data);
        if (res.data.successful) {
          // console.log(res.data.data);
          reformatData(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reformatData = (item) => {
    var result = item.map(function (el) {
      var o = Object.assign({}, el);
      o.isSelected = false;
      return o;
    });
    setCatData(result);
  };

  const onPressAbility = (id) => {
    let changeActive = catData;
    changeActive.map((item) => {
      if (item.id !== id) {
        item.isSelected = false;
      }
      return false;
    });

    let newData = changeActive.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return item;
    });

    setCatData(newData);
  };

  const searchAction = (value, item) => {
    setSelectAbility(item);
    setValue(value);
    var duration = 1000;

    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      updateInputValue(value, item);
    }, duration);
  };
  const updateInputValue = (value, item) => {
    // console.log("timer", value);
    searchItem(value, item);
  };

  const searchItem = async (item, ability) => {
    // console.log("item ==>", item.length, "ability ==>", ability.length);
    if (item.length === 0 && ability.length === 0) {
      // console.log("clear search");
      setData([]);
      fetchData(true);
    } else {
      // setPageCurrent(0);
      setData([]);
      setLoadingPage(true);
      Axios.get("/search/request", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearer,
        },
        params: {
          q: item,
          sub_ability: ability,
        },
      })
        .then((res) => {
          // console.log('54545454', res.data.data);
          if (res.data.successful) {
            setData(res.data.data.requests);
            setLoadingPage(false);
            // setIsLoading(false);
          } else {
            console.log("error");
            setLoadingPage(false);
            // toast(res.data.message);
            // setIsLoading(false);
          }
        })
        .catch((err) => {
          setLoadingPage(false);
          console.log(err);
          // setIsLoading(false);
        });
    }
  };

  return (
    <Warper>
      <Header>
        <HomeSearch
          type="Text"
          label="جست‌و‌جو در همه درخواست‌ها"
          value={value}
          onChange={(val) => searchAction(val, selectAbility)}
        />
        <Category>
          {catData.map((item, index) => (
            <CatBtn
              selected={item.isSelected}
              onClick={() => {
                onPressAbility(item.id);
                item.isSelected
                  ? searchAction(value, "")
                  : searchAction(value, item.title);
              }}
              key={index}
            >
              {item.title}
            </CatBtn>
          ))}
        </Category>
      </Header>
      <Main>
        <InfiniteScroll
          dataLength={data.length}
          next={() => fetchData()}
          hasMore={true}
          loader={
            loadingPage ? (
              <LoadMoreText>در حال دریافت داده </LoadMoreText>
            ) : (
              <LoadMoreText>!...داده یافت نشد</LoadMoreText>
            )
          }
        >
          {data.map((item, index) => (
            <Request key={index} onClick={() => onPressItem(item)}>
              <div>
                <ReqHeader>
                  <DateView>
                    <DateIcon style={{ fontSize: 14 }} />
                    <Date>{item.created_at}</Date>
                  </DateView>
                  <Title> {item.title}</Title>
                  <DateView>
                    <Date left={true}>فوریت</Date>
                    <EmergencyFill
                      style={{
                        fontSize: 14,
                        color: item.urgent === 0 ? "#cecece" : "#E9F257",
                      }}
                    />
                  </DateView>
                </ReqHeader>
                <ReqCategory>
                  <img src={handHeart} alt="Heart" />
                  <p>{item.ability}</p>
                </ReqCategory>
                <Description>{item.story}</Description>
                <ReqFooter>
                  <div>
                    <Date>{item.ability}</Date>
                    <Date>{`<`}</Date>
                    <Date>{item.sub_ability}</Date>
                  </div>
                  <Title>جزئیات</Title>
                </ReqFooter>
              </div>
              {/* <Emergency style={{ color: "#000" ,fontSize: 15 }} />
            <EmergencyFill style={{ color: "#E9F257" ,fontSize: 15}} /> */}
            </Request>
          ))}
        </InfiniteScroll>
      </Main>
    </Warper>
  );
}

export default HomePage;

const Warper = styled.div`
  top: 0px;
  width: 100%;
  height: 100%;
  /* position: fixed; */
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

const Header = styled.div`
  top: 0;
  flex-direction: column;
  /* position: fixed; */
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: flex-start;
  padding-top: 15px;
  background-color: rgba(250, 250, 250, 1);
`;

const Category = styled.div`
  width: 98%;
  height: 50px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  align-items: center;
  direction: rtl;
  ::-webkit-scrollbar {
    width: 12px;
    height: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #5c438a1e;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #009544;
    border-radius: 6px;
    transition: 0.4s ease;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #009544;
    transition: 0.4s ease;
  }
`;
const CatBtn = styled.div`
  /* min-width: 50px; */
  height: 20px;
  border-radius: 50px;
  border: 1px solid
    ${(props) => (props.selected ? "#009544" : "rgba(222, 222, 222, 1)")};
  background-color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Vista";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 5px;
  color: #6a6a6a;
  white-space: nowrap;
`;
const Main = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* margin-top: 117px; */
  align-items: center;
  justify-content: center;

  padding-bottom: 77px;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;

const Request = styled.div`
  /* height: 220px; */
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 96%;
    padding-bottom: 15px;
    /* height: 220px; */
    border: 1px solid #dedede;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;

const ReqHeader = styled.div`
  flex-direction: row-reverse;
  width: 95%;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 15px;
  /* background-color: #256; */
`;

const Date = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  margin-right: 5px;
  color: #6a6a6a;
  margin-left: ${(props) => (props.left ? "5px" : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const Title = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  direction: rtl;
  color: #009544;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const DateView = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const ReqCategory = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  & > p {
    font-family: "Vista";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-right: 8px;
    color: #6a6a6a;
  }
`;

const Description = styled.p`
  width: 95%;
  font-family: "Vista";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #585858;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  direction: rtl;
`;

const ReqFooter = styled.div`
  width: 92%;
  height: 25px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const LoadMoreText = styled.h6`
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #009544;
`;

// const data = [
//   {
//     id: 0,
//   },
//   {
//     id: 1,
//   },
//   {
//     id: 2,
//   },
//   {
//     id: 3,
//   },
//   {
//     id: 4,
//   },
//   {
//     id: 5,
//   },
//   {
//     id: 6,
//   },
//   {
//     id: 7,
//   },
//   {
//     id: 8,
//   },
//   {
//     id: 9,
//   },
//   {
//     id: 10,
//   },
// ];

// const catData = [
//   {
//     id: 1,
//     title: "تست",
//   },
//   {
//     id: 1,
//     title: "تست",
//   },
//   {
//     id: 1,
//     title: "تست",
//   },
//   {
//     id: 1,
//     title: "این یک نوشته تست است",
//   },
//   {
//     id: 1,
//     title: "تست",
//   },
//   {
//     id: 1,
//     title: "تست",
//   },
//   {
//     id: 1,
//     title: "تست",
//   },
//   {
//     id: 1,
//     title: "تست",
//   },
//   {
//     id: 1,
//     title: "تست",
//   },
//   {
//     id: 1,
//     title: "تست",
//   },
// ];
