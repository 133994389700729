import Button from "../../../components/Button";
import React from "react";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { toasty } from "../../../constants/Toast";

function StepTwo({ onPressNext, description, onChangeDescription }) {
  const [focused, setFocused] = React.useState(false);

  const handleOnFocus = () => {
    setFocused(true);
  };

  const handleOnBlur = () => {
    setFocused(false);
  };

  const nextStep = () => {
    if (description.length > 10) {
      onPressNext();
    } else {
      toasty("حداقل کاراکتر ورودی 10 می باشد", "error");
    }
  };

  return (
    <Content>
      <Title>جزئیات نیازمندی</Title>
      <Descriptions>
        برای اطلاعات بیشتر، راهنمای بالای صفحه را مشاهده فرمایید.
      </Descriptions>
      <Title color>داستان ما</Title>
      <DescriptionView focused={focused}>
        <textarea
          maxLength={1000}
          value={description}
          onChange={(e) => onChangeDescription(e.target.value)}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        <Counter length={description.length}>
          {description.length} / 1000
        </Counter>
      </DescriptionView>
      <Button
        active={description.length >= 10 ? true : false}
        text={"ادامه"}
        onPress={() => nextStep()}
      />
      <ToastContainer rtl />
    </Content>
  );
}

export default StepTwo;

const Content = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 97%;
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
`;

const Descriptions = styled.div`
  display: flex;
  width: 90%;
  font-family: "Vista";
  font-size: 14px;
  text-align: justify;
  direction: rtl;
  color: #828282;
  /* min-height: 284px; */
`;

const Title = styled.div`
  display: flex;
  width: 90%;
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  color: ${(props) => (props.color ? "#009544" : "#4B4B4B")};
  text-align: justify;
  direction: rtl;
`;

const DescriptionView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid ${(props) => (props.focused ? "#009544" : "#eee")};
  & > textarea {
    display: flex;
    height: 100%;
    width: 93%;
    background-color: transparent;
    border: none;
    outline: none;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    margin-bottom: 15px;
    font-family: Vista;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: justify;
    direction: rtl;
    color: #585858;
    transition: 0.3s ease;
    resize: none;
  }
`;

const Counter = styled.div`
  /* top: 94%;
  left: 25px; */
  font-family: Vista;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  width: 90%;

  color: ${(props) =>
    props.length < 10 ? "red" : props.length === 1000 ? "#009544" : "#585858"};
`;
