import "../../App.scss";

import React, { useState } from "react";
import { isLogin, token } from "../store/actions/user_action";
import { useHistory, useLocation } from "react-router-dom";

import Axios from "axios";
import CustomButton from "../../components/Button";
import ReactInputVerificationCode from "react-input-verification-code";
import { ToastContainer } from "react-toastify";
import myImage from "../../assets/image/logo.png";
import styled from "styled-components";
import { toasty } from "../../constants/Toast";
import { useDispatch } from "react-redux";

const Warper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 200px;
`;

const Title = styled.a`
  display: flex;
  width: 90%;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #4b4b4b;
  font-family: "Vista";
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default function Otp() {
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  let history = useHistory();
  let location = useLocation();
  const [btnLoading, setBtnLoading] = React.useState(false)

  // const onPressNext =()=>{
  //   history.push('/HomePage', {
  //     code :code ,
  //     from : 'details',
  //     foo: 'bar'
  //   })
  // }

  const login = () => {
    setBtnLoading(true)
    let data = JSON.stringify({
      phone:  location.state.phone,
      code: code,
    });
    Axios.post("/login", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // 'Content-Length': data.length.toString
      },
    })
      .then((res) => {
        //  console.log('****',res.data)
        if (res.data.successful) {
          dispatch(isLogin());
          dispatch(token(res.data.data.token));
          setBtnLoading(false)
          history.push("/HomePage", {
            foo: "bar",
          });
        } else {
          toasty(res.data.message , 'error');
          setBtnLoading(false)
          
        }
      })
      .catch((err) => {
        console.log(err);
            setBtnLoading(false)
            // setBtnLoading(false);
      });
  };

  return (
    <Warper>
      <Logo src={myImage} />
      <Title>کد فعال سازی 5 رقمی ارسال شده را وارد نمایید</Title>
      <div className="custom-styles">
        <ReactInputVerificationCode
          length={5}
          placeholder=""
          onChange={(i) => setCode(i)}
        />
      </div>
      {/* <CustomButton title="ورورد" isError={code.length === 5 ? true : false}/> */}
      <CustomButton
      loading={btnLoading}
        active={code.length === 5 ? true : false}
        text={"ادامه"}
        onPress={() => login()}
      />
      <ToastContainer rtl/>
    </Warper>
  );
}
