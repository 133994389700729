import {IS_LOGIN, LOG_OUt, TOKEN} from '../actions/user_action';

const initState = {
  isLogin: false,
  token: '',
};

const User_reducer = (state = initState, action) => {
  switch (action.type) {
    case IS_LOGIN:
      return {
        ...state,
        isLogin: true,
      };
    
    case LOG_OUt:
      return {
        isLogin: false,
        token: '',
      };
  
    case TOKEN:
      return {
        ...state,
        token: action.token,
      };
    
    default:
      return state
    
  }
};

export default User_reducer;
