import React, { useEffect, useState } from "react";

import Axios from "axios";
import InitialLoad from '../../../components/Loading'
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function History() {
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState([])
  const [offer, setOffer] = useState([])
  useEffect(() => {
    // effect
    getLimitHistory();
    return () => {
      // cleanup
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLimitHistory = () => {
    Axios.get("/limited/user/requests", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        //  console.log('limit history data ---->', res.data.data.requests);
        if (res.data.successful) {
          setRequest(res.data.data.requests);
          setOffer(res.data.data.offers);
          setLoading(false);
        } else {
          console.log("error");
          // ToastEvent(res.data.message, 'error');
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // ToastEvent('مشکلی پیش آمده', 'error');
        setLoading(false);
      });
  };

  const NavigateTo = (item) => {
    history.push("/AllHistory", {
      type: item,
    });
  };

  
  const onPressItem = (item , type) => {
    // history.push(`/Details/:${item.id}`);
    history.push("/Details", {
      myProp: item,
      from: type,
      foo: "bar",
    });
  };
  return (
    <Content>
       <InitialLoad  loading={loading}>
      <SectionView>
        <SectionHeader>
          <Title>سابقه ثبت نیازمندی‌ها</Title>
          <ShowAll
            onClick={() => NavigateTo(["Request", "سابقه ثبت نیازمندی‌ها"])}
          >
            نمایش همه
          </ShowAll>
        </SectionHeader>
        <SectionContent>
          {request.map((item, index) => (
            <Card  onClick={()=>onPressItem(item , 'Request')} key={index} show={request?.length === index + 1 ? false : true}>
              <CardTitle>{item.title}</CardTitle>
              <Ability>{item.ability}</Ability>
              <Description>{item.story}</Description>
              <Details>جزییات</Details>
            </Card>
          ))}
        </SectionContent>
      </SectionView>
      <SectionView>
        <SectionHeader>
          <Title>سابقه اجابت نیاز‌ها</Title>
          <ShowAll
            onClick={() => NavigateTo(["offer", "سابقه اجابت نیاز‌ها"])}
          >
            نمایش همه
          </ShowAll>
        </SectionHeader>
        <SectionContent>
          {offer.map((item, index) => (
            <Card onClick={()=>onPressItem(item , 'offer')} key={index} show={offer?.length === index + 1 ? false : true}>
              <CardTitle>{item.title}</CardTitle>
              <Ability>{item.ability}</Ability>
              <Description>{item.story}</Description>
              <Details>جزییات</Details>
            </Card>
          ))}
        </SectionContent>
      </SectionView>
      </InitialLoad>
    </Content>
  );
}

export default History;
const Content = styled.div`
  padding-top: 5px;
  padding-bottom: 15px;
  flex-direction: column;
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const SectionView = styled.div`
  padding-bottom: 15px;
  flex-direction: column;
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

const SectionHeader = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #eceaea;
`;

const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  margin-right: 15px;
  color: #6a6a6a;
`;
const ShowAll = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  margin-left: 15px;
  color: #f55d3e;
`;
const Details = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #009544;
`;

const Card = styled.div`
  direction: rtl;
  width: 90%;
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: ${(props) => (props.show ? "1px solid #D1D1D1" : undefined)};
`;
const CardTitle = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  color: #009544;
`;
const Ability = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #6a6a6a;
`;
const Description = styled.div`
  width: 95%;
  font-family: "Vista";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #585858;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  direction: rtl;
`;

// const data = [
//   {
//     id: 0,
//     title: "نوسازی لوله‌کشی خانه ",
//     ability: "تاسیسات ساختمان",
//     description:
//       "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم  از صنعت چاپ و با استفاده از طراحان گرافیک است.  ",
//   },
//   {
//     id: 1,
//     title: "نوسازی لوله‌کشی خانه ",
//     ability: "تاسیسات ساختمان",
//     description:
//       "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم  از صنعت چاپ و با استفاده از طراحان گرافیک است.  ",
//   },
// ];
