import "./index.scss";
import "./assets/fonts/Vista.ttf";
import "./assets/fonts/VistaBold.ttf";

import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Categories_reducer from "./pages/store/reducers/Categories_reducer";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import User_reducer from "./pages/store/reducers/User_reducer";
import reportWebVitals from "./reportWebVitals";
import storage from "redux-persist/lib/storage";

import swDev from "./swDev";

// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["User"],

  // 'Auth'
};

const rootReducer = combineReducers({
  User: User_reducer,
  Categories : Categories_reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware());
// createLogger()
const persistedStore = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistedStore} loading={null}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
swDev();
// serviceWorkerRegistration.register()
