import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Aside from "../../Styles";
import Axios from "axios";
import InitialLoad from "../../../components/Loading";
import styled from "styled-components";
import { useSelector } from "react-redux";

export default function AllHistory() {
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  let location = useLocation();
  const header = location.state.type;
  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    // effect
    callApi();
    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const callApi = () => {
    let Url = `/user/requests`;
    console.log("call");
    Axios.get(Url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        //  console.log( res.data.data)
        if (res.data.successful) {
          if (header[0] === "Request") {
            const RequestTemp = res.data?.data.requests.reverse();
            setData(RequestTemp);
          } else {
            const OfferTemp = res.data.data.offers.revers();
            setData(OfferTemp);
          }

          setLoading(false);
        } else {
          console.log(res.data);
          // ToastEvent(res.data.message, 'error');
          setLoading(false);
          // setReload(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // setReload(true);
        // ToastEvent('مشکلی پیش آمده', 'error');
      });
  };

  const onPressItem = (item, type) => {
    // history.push(`/Details/:${item.id}`);
    history.push("/Details", {
      myProp: item,
      from: type,
      foo: "bar",
    });
  };

  return (
    <Aside>
      <Header>
        <div>
          <BackBtn onClick={() => onBack()}>
            <ArrowBackIosIcon fontSize="small" />
          </BackBtn>
          <HeaderTitle>{header[1]}</HeaderTitle>
        </div>
      </Header>
      <Main>
        <InitialLoad loading={loading}>
          <Content>
            <SectionView>
              <SectionHeader>
                <Title>{header[1]}</Title>
              </SectionHeader>
              <SectionContent>
                {data.map((item, index) => (
                  <Card
                    onClick={() => onPressItem(item, header[0])}
                    key={index}
                    show={data?.length === index + 1 ? false : true}
                  >
                    <CardTitle>{item.title}</CardTitle>
                    <Ability>{item.ability}</Ability>
                    <Description>{item.story}</Description>
                    <Details>جزییات</Details>
                  </Card>
                ))}
              </SectionContent>
            </SectionView>
          </Content>
          <Background />
          <Background2 />
        </InitialLoad>
      </Main>
    </Aside>
  );
}

const Header = styled.div`
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;
  /* identical to box height */
  text-align: center;
  color: #009544;
`;

const BackBtn = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 95%;
  display: flex;
  z-index: 1;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;

  overflow-y: scroll;
  scroll-behavior: smooth;
  /* margin-top: 56px; */
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;

const SectionView = styled.div`
  padding-bottom: 15px;
  flex-direction: column;
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

const SectionHeader = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #eceaea;
`;

const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  margin-right: 15px;
  color: #6a6a6a;
`;

const Details = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #009544;
`;

const Card = styled.div`
  direction: rtl;
  width: 90%;
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: ${(props) => (props.show ? "1px solid #D1D1D1" : undefined)};
`;
const CardTitle = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  color: #009544;
`;
const Ability = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #6a6a6a;
`;
const Description = styled.div`
  width: 95%;
  font-family: "Vista";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #585858;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  direction: rtl;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  max-width: 768px;
  height: 111px;
  /* top: 120px; */
  z-index: -0.54;
  /* left: -1px; */

  background: #009544;
`;
const Background2 = styled.div`
  position: absolute;
  width: 100%;
  max-width: 768px;
  height: 14px;
  /* left: -1px; */
  top: 180px;
  z-index: -0.54;
  background: #009544;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
`;
