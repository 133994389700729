import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    direction: "rtl",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 300,
    height: "100%",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

const SubAbilityView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #cae7d7;
  border-radius: 15px;
  text-align: center;
  font-family: vista;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #6a6a6a;
  & > p {
padding: 3px;
  }
`;

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function Grid({ data  , onPress}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList cellHeight={102} cols={3} className={classes.gridList}>
        {data.map((tile) => (
          <GridListTile key={tile.id}>
            <SubAbilityView onClick={()=>onPress(tile)} key={tile.id}>
              <p>{tile.title}</p>
            </SubAbilityView>
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
