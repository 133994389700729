import React, { Fragment } from "react";

import { ImpulseSpinner } from "react-spinners-kit";
import styled from "styled-components";

function Button({ text, onPress, active, loading = false }) {
  return (
    <Fragment>
      {loading ? (
        <LoadingContainer>
          <ImpulseSpinner
            size={50}
            frontColor="#009544"
            backColor={"#cecece"}
          />
        </LoadingContainer>
      ) : (
        <Container active={active} onClick={() => onPress()}>
          <Text active={active}>{text}</Text>
        </Container>
      )}
    </Fragment>
  );
}

export default Button;

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  width: 228px;
  height: 42px;
  background: ${(props) => (props.active ? "#009544" : "#FFFF")};
  border: 2px solid #009544;
  box-sizing: border-box;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  font-family: Vista;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: ${(props) => (props.active ? "#FFFF" : "#009544")};
`;

const LoadingContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  width: 228px;
  height: 42px;
  align-items: center;
  justify-content: center;
`;
