import React from "react";
import styled from "styled-components";
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  position: relative;
  margin-top: 10px;
  width: 85%;

  & > input {
    border: 1px solid
      ${(props) =>
        props.focused ? (props.error ? "#e77674" : "#009544") : "#eee"};
    border-radius: 20px;
    background-color: transparent;
    outline: none;
    padding: 12px 3px 12px 15px;
    font-size: 16px;
    transition: all 0.2s ease;
    /* z-index: 500; */
    direction: rtl;
    padding-right: 20px;
    font-family: Vista;
  }
  & > label {
    color: #757575;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-5px);
    right: 15px;
    transition: all 0.2s ease;
    /* z-index: 500; */
    font-family: Vista;

    ${(props) =>
      props.focused &&
      `
      font-size: 12px;
      top : -2px ;
      // z-index: 501;
      background: white;
      padding: 0 8px;
      font-family : Vista
    `}
  }
`;
/**
 * A Plaid-inspired custom input component
 *
 * @param {string} value - the value of the controlled input
 * @param {string} type - the type of input we'll deal with
 * @param {string} label - the label used to designate info on how to fill out the input
 * @param {function} onChange - function called when the input value changes
 * @param {function} onFocus - function called when the input is focused
 * @param {function} onBlur - function called when the input loses focus
 * @param {function} setRef - function used to add this input as a ref for a parent component
 */
const Input = ({
  value,
  type,
  label,
  onChange,
  onFocus,
  onBlur,
  setRef,
  onError,
  idDisabled,
  maxLength ,
  ...props
}) => {
  const [focused, setFocused] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleOnFocus = () => {
    setFocused(true);
    onFocus();
  };

  const handleOnBlur = () => {
    setFocused(false);
    validateValue(value);
    onBlur();
  };

  const validateValue = (val) => {
    if (type === "phoneNumber") {
      const pattern = /^(\+98?)?{?(0?9[0-9]{9,9}}?)$/;
      if (val)
        if (val.match(pattern)) {
          setError(null);
          onError(true);
        } else {
          setError("شماره نامعتبر");
          onError(false);
        }
      else {
        onError(true);
        setError(null);
      }
      // VERY simple email validation
      // if (val.indexOf("@") === -1) {
      //   setError("email is invalid");
      // } else {

      //   setError(null);
      // }
    }

    // ... any other validation you could think of
    // ... maybe even pass in an additional validation function as a prop?
  };

  const handleOnChange = (val) => {
    validateValue(val);
    onChange(val);
  };

  const renderLabel = () => {
    if (label) {
      // if we have an error
      if (error) {
        return <label>{error}</label>;
      }

      return <label>{label}</label>;
    }
    return null;
  };

  const isFocused = focused || String(value).length || type === "date";

  return (
    <InputContainer focused={isFocused} error={error}>
      {renderLabel()}
      <input
      disabled={idDisabled}
        value={value}
        type={type}
        onChange={(e) => handleOnChange(e.target.value)}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        ref={(ref) => setRef(ref)}
        maxLength={maxLength}
        {...props}
      />
     
    </InputContainer>
  );
};

Input.defaultProps = {
  type: "text",
  label: "",
  onChange: (text) => {
    console.error(`Missing onChange prop: ${text}`);
  },
  onFocus: () => {},
  onBlur: () => {},
  setRef: () => {},
};

export default Input;
