export const IS_LOGIN = 'IS_LOGIN';
export const TOKEN = 'TOKEN';
export const LOG_OUt = 'LOG_OUt';

export const isLogin = () => {
  return {
    type: IS_LOGIN,
  };
};

export const token = (token) => {
  return {
    type: TOKEN,
    token,
  };
};

export const logout = () => {
  return {
    type: LOG_OUt,
  };
};
