import React, { Fragment } from "react";

import BadgeAvatars from "./Avatar";
import styled from "styled-components";

function OfferView({ status, data , completeRequest ,showModal  }) {
    console.log(data);
  return (
    <Fragment>
      {status ? (
        <Category>
          <BadgeAvatars image={data.image} newMessage={false} count={0} />
          <SubCat>
              {
                data.completed ? (<Title>اجابت توسط {data['second_name']?.length === 0 ? data['name'] : data['second_name']} انجام شد </Title>) :(
                    <Title color={"#585858"}>
                    {data['second_name']?.length === 0 ? data['name'] : data['second_name']}{" "}
                    درخواست شما را اجابت می کند
                  </Title>
                ) 
              }
              <Row>
                  <Title onClick={()=>showModal(
                      {
                          id : data['user_id'] ,
                          name : data['name'],
                          second_name :data['second_name'],
                          avatar : data.image
                      }
                  )}>
                    ارتباط با{" "}
                    {data['second_name']?.length === 0 ? data['name'] : data['second_name']}
                  </Title>
                  <Title onClick={()=>completeRequest(data , true)}>
                    {data.completed ? null : (
                      <Fragment>{status ? "اتمام" : "تایید"}</Fragment>
                    )}
                  </Title>
                </Row>
          </SubCat>
        </Category>
      ) : (
        <Fragment>
          {data.map((item, index) => (
            <Category key={index}>
              <BadgeAvatars
                image={item.image}
                newMessage={false}
                count={0}
                size={"small"}
              />
              <SubCat>
                <Title color={"#585858"}>
                  {item?.second_name.length === 0 ? item.name : item.second_name}{" "}
                  درخواست شما را اجابت می کند
                </Title>
                <Row>
                  <Title onClick={()=>showModal(
                      {
                          id : item.user_id ,
                          name : item.name,
                          second_name : item.second_name,
                          avatar : item.image
                      }
                  )}>
                    ارتباط با{" "}
                    {item?.second_name.length === 0
                      ? item.name
                      : item.second_name}
                  </Title>
                  <Title onClick={()=>completeRequest(item , false)}>
                    {data.completed ? null : (
                      <Fragment>{status ? "اتمام" : "تایید"}</Fragment>
                    )}
                  </Title>
                </Row>
              </SubCat>
            </Category>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
}

export default OfferView;
const Category = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 95%;
  align-items: center;
  margin-top: 13px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 20px;
`;

const SubCat = styled.div`
width: 100%;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: center;
  margin-right: 10px;

  & > div {
    display: flex;
    flex-direction: row-reverse;
    /* height: 17px; */
  }
`;

const Title = styled.p`
  direction: rtl;
  text-align: right;
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  /* line-height: 24px; */
  color: ${(props) => (props.color ? props.color : "#009544")};
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const Row = styled.div`
 margin-bottom: 5px;
  height: 24px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
`;
