import Aside from "../Styles";
import Axios from "axios";
import { BottomSheet } from "react-spring-bottom-sheet";
import Box from "@material-ui/core/Box";
import CustomizedTabs from "../../components/MyTab";
import History from "../tabPage/profile/History";
import Logo from "../../assets/image/logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import Pusher from "pusher-js";
import React from "react";
import Settings from "../tabPage/profile/Settings";
import SimpleBadge from "../../components/Badge";
import SwipeableViews from "react-swipeable-views";
import TemporaryDrawer from "../../components/Drawer";
import styled from "styled-components";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

// Pusher.logToConsole = true;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Profile() {
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [guide, setGuide] = useState("");
  const [value, setValue] = React.useState(0);
  const [drawer, setDrawer] = React.useState(false);

  // pusher config
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  var pusher = new Pusher("4215284dadcbbf23d798", {
    auth: {
      headers: {
        Accept: "application/json",
        Authorization: bearer,
      },
    },
    cluster: "ap2",
    activityTimeout: [60000],
    forceTLS: true,
    encrypted: false,
    curl_options: {
      CURLOPT_SSL_VERIFYHOST: 0,
      CURLOPT_SSL_VERIFYPEER: 0,
    },
  });

  useEffect(() => {
    // effect
    loadPusher();
    getGuid();
    callApi();
    return () => {
      pusher.disconnect();
      console.log("disconnect");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPusher = () => {
    console.log("loadPusher");
    var channel = pusher.subscribe(`CountMessage`);
    channel.bind("App\\Events\\CountMessage", function (data) {
      console.log("****", data);
      callApi();
    });
  };

  const callApi = () => {
    let bearer = `Bearer ${User.token}`;
    Axios.get("/user", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        console.log("****", res.data.data.unread_messages);
        if (res.data.successful) {
          setCount(res.data.data.unread_messages);
        } else {
          // console.log('error');
          // ToastEvent(res.data.message, 'error');
        }
      })
      .catch((err) => {
        console.log(err);
        // ToastEvent('مشکلی پیش آمده', 'error');
      });
  };

  //===================================

  const getGuid = () => {
    Axios.get("/guide", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.successful) {
          setGuide(res.data.data.app_guide);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  let history = useHistory();

  const NavigateTo = (item) => {
    history.push("/ChatList", {
      type: item,
    });
  };

  const onShowHelp = () => {
    console.log("show help");
    setDrawer(false);
    setOpen(true);
  };

  function onDismiss() {
    setOpen(false);
  }

  return (
    <Aside>
      <TemporaryDrawer
        showHelp={() => onShowHelp()}
        drawer={drawer}
        onCloseDrawer={() => setDrawer(false)}
      />
      <Background />
      <Background2 />
      <Header>
        <div>
          <AppLogo src={Logo} />
          <RightAction>
            <MenuIcon
              onClick={() => setDrawer(true)}
              style={{ marginRight: 10 }}
            />
            <SimpleBadge count={count} onPressItem={() => NavigateTo()} />
          </RightAction>
        </div>
      </Header>
      <Main>
        <CustomizedTabs value={value} onchangeTab={(val) => setValue(val)} />
        
        <SwipeableViews
          axis={"x-reverse"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0}>
            <Settings />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <History />
          </TabPanel>
        </SwipeableViews>
      </Main>
      <BottomSheet onDismiss={onDismiss} open={open}>
        <SheetContent>
          <HelpText>{guide}</HelpText>
        </SheetContent>
      </BottomSheet>
    </Aside>
  );
}

const Header = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  max-width: 768px;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const Main = styled.div`
  width: 100%;
  margin-top: 47px;
  padding-bottom: 65px;
  overflow-y: scroll;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const RightAction = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const AppLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 8px;
`;
// const AppEdit = styled.img`
//   width: 25px;
//   height: 25px;
//   margin-left: 16px;
//   margin-top: 15px;
//   position: absolute;
  
// `;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 211px;
  /* left: -1px; */
  max-width: 768px;
  background: #0cb95b;
`;
const Background2 = styled.div`
  position: absolute;
  width: 100%;
  height: 14px;
  /* left: -1px; */
  top: 219px;
  max-width: 768px;

  background: #009544;
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

const SheetContent = styled.div`
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
  align-items: center;
  justify-content: flex-start;

  /* padding: 1vh 3vh 3vh; */
`;

const HelpText = styled.p`
  width: 95%;
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 27px;
  text-align: justify;
  direction: rtl;
  color: #828282;
`;
