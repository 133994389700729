import React from "react";
import styled from "styled-components";

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
`;

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 76px;
  height: 22px;
  border-radius: 100px;
  border: 1px solid #dedede;
  position: relative;
  transition: background-color 0.2s;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
`;

const SwitchButton = styled.span`
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 18px;
  height: 18px;
  border-radius: 45px;
  transition: 0.2s;
  background: grey;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  ${SwitchInput}:checked + ${SwitchLabel} & {
    right: calc(100% - 2px);
    transform: translateX(100%);
    background: #e9f257;
  }
  ${SwitchLabel}:active & {
    width: 45px;
  }
`;



const LabelOFF = styled.div`
  display: flex;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Vista;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  justify-content:${(props => props.toggled ?  'flex-end' : 'flex-start')} ;
  color: #6a6a6a;
 
`;

const Switch = ({ id, toggled, onChange }) => {
  return (
    <>
      <SwitchInput
        className="switch-checkbox"
        id={id}
        type="checkbox"
        checked={toggled}
        onChange={onChange}
      />
      <SwitchLabel className="switch-label" htmlFor={id}>
         <LabelOFF toggled={toggled}>فوریت</LabelOFF>
        <SwitchButton className="switch-button" />
      
      </SwitchLabel>
    </>
  );
};

export default Switch;
