import { makeStyles, withStyles } from "@material-ui/core/styles";

import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 5,
    "& > span": {
      //   maxWidth: 40,

      width: "100%",
      backgroundColor: "#F55D3E",
    },
  },
})((props) => (
  <Tabs
    variant="fullWidth"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

const StyledTab = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    opacity: 1,
    textTransform: "none",
    fontFamily: "Vista",
    color: "#4B4B4B",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    "&:focus": {
      opacity: 1,
      // backgroundColor: '#fff',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },

  demo2: {
    direction: "rtl",
    backgroundColor: "#fff",
  },
}));

export default function CustomizedTabs({ value, onchangeTab }) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onchangeTab(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo2}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="تنظیمات خیر من" />
          <StyledTab label="سوابق خیر من" />
        </StyledTabs>
      </div>
    </div>
  );
}
