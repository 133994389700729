import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { minusCategory } from "../pages/store/actions/Categories_action";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    direction: "rtl",
    display: "flex",
    justifyContent: "right",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    paddingLeft: theme.spacing(0.7),
    margin: theme.spacing(0.5),
    fontFamily: "Vista",
    fontSize: 12,
    color: "#6A6A6A",
  },
}));

export default function ChipsArray() {
  const [first, setFirst] = useState(false);
  const classes = useStyles();
  const Categories = useSelector((state) => state.Categories?.category);
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  const dispatch = useDispatch();

  const pressMinus = async (id) => {
    await dispatch(minusCategory(id));
    // let data = Categories.filter((chip) => chip.id !== id)

    // callSaveApi(data);
  };

  const callSaveApi = (data) => {
    let temp = [];
    data.map((i) => temp.push(i.id));

    let body = JSON.stringify({
      sub_abilities: temp,
    });
    let Url = "/user/set/subability";
    Axios.post(Url, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        // console.log( "res")
        if (res.data.successful) {
          console.log(res.data);
          // console.log('set Profile');
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // effect
    if (!first) {
      setFirst(true);
    } else {
      callSaveApi(Categories);
    }

    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Categories]);

  return (
    <Paper elevation={0} component="ul" className={classes.root}>
      {Categories?.map((data) => {
        return (
          <li key={data.id}>
            <Chip
              size="small"
              variant="outlined"
              label={data.title}
              onDelete={() => pressMinus(data.id)}
              className={classes.chip}
            />
          </li>
        );
      })}
    </Paper>
  );
}
