import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import styled from "styled-components";

function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
    </SvgIcon>
  );
}

/**
 * A Plaid-inspired custom input component
 *
 * @param {string} value - the value of the controlled input
 * @param {string} type - the type of input we'll deal with
 * @param {string} label - the label used to designate info on how to fill out the input
 * @param {function} onChange - function called when the input value changes
 * @param {function} onFocus - function called when the input is focused
 * @param {function} onBlur - function called when the input loses focus
 * @param {function} setRef - function used to add this input as a ref for a parent component
 */
const HomeSearch = ({
  value,
  type,
  label,
  onChange,
  onFocus,
  onBlur,
  setRef,
  onError,
  ...props
}) => {
  const [focused, setFocused] = React.useState(false);
  // const [error, setError] = React.useState(null);

  const handleOnFocus = () => {
    setFocused(true);
    onFocus();
  };

  const handleOnBlur = () => {
    setFocused(false);
    // validateValue(value);
    onBlur();
  };

  const handleOnChange = (val) => {
    // validateValue(val);
    onChange(val);
  };

  const isFocused = focused || String(value).length || type === "date";

  return (
    <InputContainer focused={isFocused}>
      <input
        placeholder={label}
        value={value}
        type={type}
        onChange={(e) => handleOnChange(e.target.value)}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        ref={(ref) => setRef(ref)}
        {...props}
      />
      <SearchIcon style={{ color: isFocused ? "#009544" : "#CBCBCB" }} />
    </InputContainer>
  );
};

HomeSearch.defaultProps = {
  type: "text",
  label: "",
  onChange: (text) => {
    console.error(`Missing onChange prop: ${text}`);
  },
  onFocus: () => {},
  onBlur: () => {},
  setRef: () => {},
};

export default HomeSearch;

const InputContainer = styled.div`
  width: 90%;
  border: 1px solid ${(props) => (props.focused ? "#009544" : "#DEDEDE")};
  border-radius: 10px;
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  background-color: #fff;
  padding: 2px 5px 2px 5px;
  align-items: center;
  /* margin-right: 5px;
  margin-left: 5px; */

  & > input {
    width: 90%;
    height: 40px;
    border: 0;
    background-color: #ffff;
    outline: none;
    padding-left: 15px;
    padding-right: 10px;
    font-size: 12px;
    transition: all 0.2s ease;
    z-index: 500;
    direction: rtl;
    font-family: Vista;
    ::placeholder {
      color: #cbcbcb;
    }
  }
`;


