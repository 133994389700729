import "react-toastify/dist/ReactToastify.css";
import "../../constants/Toast";

import React, { Fragment, useEffect, useState } from "react";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Aside from "../Styles";
import Axios from "axios";
import { BottomSheet } from "react-spring-bottom-sheet";
import HomeSearch from "../../components/HomeSearch";
import StepOne from "../tabPage/registration/StepOne";
import StepThree from "../tabPage/registration/StepThree";
import StepTwo from "../tabPage/registration/StepTwo";
import { ToastContainer } from "react-toastify";
import { provinces } from "../../constants/city";
import styled from "styled-components";
import { toasty } from "../../constants/Toast";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Registration() {
  let history = useHistory();
  let location = useLocation();
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;

  const [step, setStep] = useState(0);
  const [btnLoading, setBtnLoading] = useState(false);
  //
  const [provinceSearchData, setProvinceSearchData] = useState(provinces);
  const [searchCites, setSearchCites] = useState([]);
  const [modalType, setModalType] = useState("");
  const [selectedCityData, setSelectedCityData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  //===>
  // step one
  const [title, setTitle] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [ability, setAbility] = useState("");
  const [subAbility, setSubAbility] = useState("");
  const [subAbilityId, setSubAbilityId] = useState("");
  const [emergency, setEmergency] = useState("");
  const [open, setOpen] = useState(false);
  const [loadingSub, setloadingSub] = useState(false);
  const [guide, setGuide] = useState("");
  // step two
  const [description, setDescription] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // step tree
  const [images, setImages] = React.useState([]);
  const [serverImage, setServerImage] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  useEffect(() => {
    // console.log(location.state);
    if (location.state?.from === "Edit") {
      setEditState(location.state.myProp);
    }
    // effect
    getCategories();
    getGuid();
    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // effect
    if (location.state?.from !== "Edit") {
      console.log("clear");
      cleanState();
    }
    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const setEditState = (data) => {
    setTitle(data.title);
    setProvince(data.state);
    setCity(data.city);
    setAbility(data.ability);
    setServerImage(data.image);
    setEmergency(data.urgent === 0 ? false : true);
    setDescription(data.story);
    getSubCategories(data.ability_id);
    setSubAbility(data.sub_ability);
  };

  const getGuid = () => {
    Axios.get("/guide", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log('*-*--*-->',res.data);
        if (res.data.successful) {
          setGuide(res.data.data.guide);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function onDismiss() {
    setOpen(false);
  }

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <StepOne
            bearer={`Bearer ${User.token}`}
            title={title}
            province={province}
            city={city}
            ability={ability}
            subAbility={subAbility}
            emergency={emergency}
            onChangeTitle={(text) => setTitle(text)}
            loadingSub={loadingSub}
            isSelect={subCatData.length === 0 ? false : true}
            showModal={(type) => {
              setModalType(type);
              setProvinceSearchData(provinces);
              setCitiesData(province);
              setSearchValue("");
              if (type[0] === "city" && province.length > 0) {
                setOpen(true);
              } else if (type[0] === "city" && province.length <= 0) {
                setOpen(false);
              } else {
                setOpen(true);
              }
            }}
            onPressNext={() => {
              setStep(1);
            }}
            handleChange={() => setEmergency(!emergency)}
          />
        );
      case 1:
        return (
          <StepTwo
            description={description}
            onChangeDescription={(val) => setDescription(val)}
            onPressNext={() => setStep(2)}
          />
        );

      default:
        return (
          <StepThree
            images={images}
            setImages={(value) => {
              setImages(value);
              console.log("====>", value);
            }}
            onPressNext={() => submitRegisteration()}
            serverImage={serverImage}
            edit={location.state?.from === "Edit"}
            onServerImageRemove={(id) => {
              setServerImage((images) =>
                images.filter((image) => image.id !== id)
              );
              setDeleteId((value) => [...value, id]);
            }}
            onSubmitEdit={() => onSubmitEdit()}
            btnLoading={btnLoading}
          />
        );
    }
  };

  const setCitiesData = (e) => {
    provinces.map(function (item) {
      if (item.name === e) {
        // console.log(item.cities);
        setSelectedCityData(item.cities);
        //  console.log('/**/*/---> ',item.cities);

        setSearchCites(item.cities);
        // setAbility("");
        // setSubAbility("");
      }
      return false;
    });
  };

  const searchOn = (val) => {
    setSearchValue(val);
    callSearch(val);
  };

  const callSearch = (text) => {
    if (modalType[0] === "city") {
      const newData = selectedCityData.filter((item) => {
        const itemData = `${item.toUpperCase()}`;
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearchCites(newData);
      // console.log(newData);
    } else {
      const newData = provinces.filter((item) => {
        const itemData = `${item.name.toUpperCase()}`;
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setProvinceSearchData(newData);
    }
  };

  const renderSheetContent = () => {
    switch (modalType[0]) {
      case "province":
        return (
          <Fragment>
            {provinceSearchData.map((item, index) => (
              <SheetItem
                onClick={() => {
                  setProvince(item.name);
                  setCity("");
                  setCitiesData(item.name);
                  setOpen(!open);
                }}
                key={index}
                last={provinceSearchData.length === index + 1 ? true : false}
              >
                {item.name}
              </SheetItem>
            ))}
          </Fragment>
        );

      case "city":
        return (
          <Fragment>
            {searchCites.map((item, index) => (
              <SheetItem
                onClick={() => {
                  setCity(item);
                  setOpen(!open);
                }}
                key={index}
                last={searchCites.length === index + 1 ? true : false}
              >
                {item}
              </SheetItem>
            ))}
          </Fragment>
        );
      case "ability":
        return (
          <Fragment>
            {catData.map((item, index) => (
              <SheetItem
                onClick={() => {
                  setAbility(item.title);
                  setSubAbility("");
                  setSubCatData([]);
                  getSubCategories(item.id);
                  setOpen(!open);
                }}
                key={index}
                last={catData.length === index + 1 ? true : false}
              >
                {item.title}
              </SheetItem>
            ))}
          </Fragment>
        );
      case "subAbility":
        return (
          <Fragment>
            {subCatData.map((item, index) => (
              <SheetItem
                onClick={() => {
                  setSubAbility(item.title);
                  setSubAbilityId(item.id);
                  setOpen(!open);
                }}
                key={index}
                last={subCatData.length === index + 1 ? true : false}
              >
                {item.title}
              </SheetItem>
            ))}
          </Fragment>
        );

      default:
        return <HelpText>{guide}</HelpText>;
    }
  };

  const onPressBack = () => {
    // console.log("back");
    setStep(step - 1);
  };

  const getCategories = () => {
    Axios.get("/abilities", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.data.successful) {
          // console.log(res.data.data);
          setCatData(res.data.data);
          // setCat(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubCategories = (id) => {
    setloadingSub(true);
    Axios.get(`/subabiliyt/ability/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.data.successful) {
          // console.log("++++>", res.data.data);
          setSubCatData(res.data.data);
          setloadingSub(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitRegisteration = () => {
    const time = "image" + new Date().getMilliseconds();

    setBtnLoading(true);
    var body = new FormData();
    let Url = "/requests";
    body.append("story", description);
    body.append("sub_ability_id", subAbilityId);
    body.append("city_name", city);
    body.append("title", title);
    body.append("state", province);
    body.append("urgent", emergency ? "1" : "0");

    images.map((item, index) =>
      body.append(
        `image[${index}]`,
        dataURLtoFile(item, time)
        //  {
        //   uri: item.data_url,
        //   name: "image.jpg",
        //   type: "image/jpeg",
        // }
      )
    );

    // // setLoading(false)
    Axios.post(Url, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: bearer,
      },
    })
      .then((res) => {
        // console.log( "res")
        if (res.data.successful) {
          toasty(
            "ثبت نیازمندی با موفقیت صورت پذیرفت و در صف اجابت قرار گرفت.",
            "success"
          );
          setBtnLoading(false);
          cleanState();
        } else {
          console.log(res.data);
          toasty("مشکلی پیش آمده", "error");
          setBtnLoading(false);
        }
      })
      .catch((err) => {
        setBtnLoading(false);
        console.log(err);
        toasty("مشکلی پیش آمده", "error");
      });
  };

  const onSubmitEdit = () => {
    const time = "image" + new Date().getMilliseconds();
    setBtnLoading(true);
    var body = new FormData();

    let Url = `/requests/${location.state.myProp.id}`;

    body.append("_method", "PUT");
    body.append("story", description);
    if (subAbilityId !== "") {
      body.append("sub_ability_id", subAbilityId);
    }
    body.append("city_name", city);
    body.append("title", title);
    body.append("state", province);
    body.append("urgent", emergency ? "1" : "0");
    if (deleteId.length !== 0) {
      body.append("deleted_images", JSON.stringify(deleteId));
    }
    images.map((item, index) =>
      body.append(
        `images[${index}]`,
        dataURLtoFile(item, time)
        //  {
        //   uri: item.data_url,
        //   name: "image.jpg",
        //   type: "image/jpeg",
        // }
      )
    );

    // // // // setLoading(false)
    Axios.post(Url, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        // console.log( "res")
        if (res.data.successful) {
          toasty("ویرایش با موفقیت انجام شد", "success");
          cleanState();
          setBtnLoading(false);
          history.goBack();
        } else {
          console.log(res.data);
          toasty(res.data.message, "error");
          setBtnLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toasty("مشکلی پیش آمده", "error");
        setBtnLoading(false);
      });
  };

  const cleanState = () => {
    setTitle("");
    setProvince("");
    setCity("");
    setAbility("");
    setSubAbilityId("");
    setSubAbility("");
    setDescription("");
    setEmergency(false);
    setStep(0);
  };

  const dataURLtoFile = (dataUrl, filename) => {
    console.log("*****", dataUrl);
    let arr = dataUrl.data_url.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  return (
    <Aside>
      <Header>
        <div>
          {step === 1 ? (
            <BackBtn
              onClick={() => {
                setModalType("help");
                setOpen(true);
              }}
            >
              <HelpText>راهنما</HelpText>
            </BackBtn>
          ) : (
            <div />
          )}
          <TitleNavigate>
            {step > 0 ? (
              <ArrowForwardIosIcon
                fontSize={"small"}
                onClick={() => onPressBack()}
              />
            ) : null}
            <HeaderTitle> ثبت نیازمندی‌ها</HeaderTitle>
          </TitleNavigate>
        </div>
      </Header>
      <NavigatorView>
        <Navigate
          selected={step === 0 || step === 1 || step === 2 ? true : false}
        />
        <Navigate selected={step === 1 || step === 2 ? true : false} />
        <Navigate selected={step === 2 ? true : false} />
      </NavigatorView>
      <Main>{renderContent()}</Main>
      <BottomSheet
        onDismiss={onDismiss}
        open={open}
        header={
          modalType === "help" ||
          modalType[0] === "ability" ||
          modalType[0] === "subAbility" ? null : (
            <HomeSearch
              type="none"
              label={"جست‌و‌جو در " + modalType[1]}
              value={searchValue}
              onChange={(val) => searchOn(val)}
            />
          )
        }
      >
        <SheetContent>{renderSheetContent()}</SheetContent>
      </BottomSheet>
      <ToastContainer rtl />
    </Aside>
  );
}

const Header = styled.div`
  top: 0;
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 5px;
  /* identical to box height */
  text-align: center;
  color: #009544;
`;

const BackBtn = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  align-items: center;
  justify-content: center;
`;

const HelpText = styled.p`
  width: 95%;
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 27px;
  text-align: justify;
  direction: rtl;
  color: #828282;
`;

const NavigatorView = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  /* position: fixed; */
  /* margin-top: 46px; */
`;

const Navigate = styled.div`
  height: 5px;
  width: 32.8%;
  background-color: ${(props) => (props.selected ? "#009544" : "#DEDEDE")};
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  scroll-behavior: auto;
  /* margin-top: 54px; */
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 65px;
`;

const SheetContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: flex-start;

  /* padding: 1vh 3vh 3vh; */
`;

const SheetItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  text-align: right;
  height: 40px;
  border-bottom: ${(props) => (props.last ? "0px" : ".5px")} solid gray;
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 27px;
  text-align: center;
  color: #828282;
`;

const TitleNavigate = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 10px;
`;
