
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const MINUS_CATEGORY = 'MINUS_CATEGORY';
export const ADD_GROUP = 'ADD_GROUP';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY'
export const minusCategory = (id) => {
  return {
    type: MINUS_CATEGORY,
    id
  };
};

export const addCategory = (data) => {
  return {
    type: ADD_CATEGORY,
    data
  };
};

export const addGroup =(data)=>{
  return{
    type :ADD_GROUP,
    data
  }
}

export const clearCategory =()=>{
  return{
    type : CLEAR_CATEGORY
  }
}
