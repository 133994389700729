import {
  ADD_CATEGORY,
  ADD_GROUP,
  CLEAR_CATEGORY,
  MINUS_CATEGORY,
} from "../actions/Categories_action";

const initState = {
  category: [],
};

const Categories_reducer = (state = initState, action) => {
  switch (action.type) {
    case MINUS_CATEGORY:
      const existCategoryIndex = state.category.findIndex(
        (i) => i.id === action.id
      );
      const currentRemoveData = [...state.category];
      if (existCategoryIndex >= 0) {
        currentRemoveData.splice(existCategoryIndex, 1);
      }
      return {
        ...state,
        category: currentRemoveData,
      };

    case ADD_CATEGORY: {
      const data = action.data;
      const existCategoryIndex = state.category.findIndex(
        (i) => i.id === data.id
      );
      const currentRemoveData = [...state.category];
      if (existCategoryIndex >= 0) {
        currentRemoveData.splice(existCategoryIndex, 1);
        return {
          ...state,
          category: currentRemoveData,
        };

        // return state;
      } else if (state.category?.length < 10) {
        return {
          ...state,
          category: state.category.concat(action.data),
        };
      } else {
        return state;
      }
    }

    case ADD_GROUP: {
      return {
        category: action.data,
      };
    }

    case CLEAR_CATEGORY: {
      return {
        category: [],
      };
    }

    default:
      return state;
  }
};

export default Categories_reducer;
