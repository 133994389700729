import { makeStyles, withStyles } from "@material-ui/core/styles";

import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
   
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  demo : {
    fontFamily:'Vista',
    color: '#4B4B4B',
  }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        fontFamily:'Vista',
        color: '#009544',
        backgroundColor :'#E8E8E8'
    },
  }))(Badge);

export default function SimpleBadge({count = 0 , onPressItem}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StyledBadge
      onClick={()=>onPressItem()}
        badgeContent={count}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        color="primary"
      >
        <MailIcon color={"error"} />
      </StyledBadge>
    </div>
  );
}
