import React, { useEffect, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Aside from "../../Styles";
import Axios from "axios";
import BadgeAvatars from "../../../components/Avatar";
import InitialLoad from "../../../components/Loading";
import Pusher from "pusher-js";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// Pusher.logToConsole = true;

export default function AllHistory() {
  const [list, setList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  let history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  const NavigateTo = (item) => {
    console.log('item is' , item);
    history.push("/Chat", {
      data: item,
    });
  };

  useEffect(() => {
    // effect
    loadIdentity(true);
    loadPusher();
    return () => {
      // cleanup
      pusher.disconnect();
      console.log("disconnect");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // pusher config

  var pusher = new Pusher("4215284dadcbbf23d798", {
    auth: {
      headers: {
        Accept: "application/json",
        Authorization: bearer,
      },
    },
    cluster: "ap2",
    activityTimeout: [60000],
    forceTLS: true,
    encrypted: false,
    curl_options: {
      CURLOPT_SSL_VERIFYHOST: 0,
      CURLOPT_SSL_VERIFYPEER: 0,
    },
  });
  const loadPusher = () => {
    var channel = pusher.subscribe(`CountMessage`);

    channel.bind("App\\Events\\CountMessage", function (data) {
      loadIdentity();
    });
  };

  const loadIdentity = (type) => {
    if (type === true) {
      setPageLoading(true);
    }
    Axios.get("/history", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then(function (res) {
        //console.log(res.data.conversations)
        // console.log("*/*/*", res.data);

        if (res.data.successful) {
          setList(res.data.data);
          setPageLoading(false);
        } else {
          console.log("error");
          setPageLoading(false);
        }
      })

      .catch(function (e) {
        setPageLoading(false);
      });
  };

  return (
    <Aside>
      <Header>
        <div>
          <BackBtn onClick={() => onBack()}>
            <ArrowBackIosIcon fontSize="small" />
          </BackBtn>
          <HeaderTitle>پیام‌ها</HeaderTitle>
        </div>
      </Header>
      <Main>
        <InitialLoad loading={pageLoading}>
          <Content>
            {list.map((item, index) => (
              <CardView
                key={index}
                onClick={() => NavigateTo(item)}
                show={list?.length === index + 1 ? false : true}
              >
                <BadgeAvatars
                  image={item.avatar}
                  newMessage={item.unread_messages > 0 ? true : false}
                  count={item.unread_messages}
                />
                <Name>{item.second_name?.length ?  item.second_name : item.name}</Name>
              </CardView>
            ))}
          </Content>
        </InitialLoad>
      </Main>
    </Aside>
  );
}

const Header = styled.div`
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;
  /* identical to box height */
  text-align: center;
  color: #009544;
`;

const BackBtn = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 95%;
  flex-direction: column;
  display: flex;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* margin-top: 56px; */
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;

const CardView = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: ${(props) => (props.show ? "1px solid #D1D1D1" : undefined)};
`;
const Name = styled.div`
  font-family: Vista;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #6a6a6a;
`;
