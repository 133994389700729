import "react-modern-calendar-datepicker/lib/DatePicker.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as AbilityImg } from "../../../assets/image/Ability.svg";
import { ReactComponent as ArrowBtn } from "../../../assets/image/arrowBtn.svg";
import Axios from "axios";
import { BottomSheet } from "react-spring-bottom-sheet";
import Button from "../../../components/Button";
import { Calendar } from "react-modern-calendar-datepicker";
import ChipsArray from "../../../components/Chip";
import ImageUploading from "react-images-uploading";
import InitialLoad from "../../../components/Loading";
import Input from "../../../components/Input";
import ListButton from "../../../components/ListButton";
import { ToastContainer } from "react-toastify";
import { addGroup } from "../../store/actions/Categories_action";
import Edit from "../../../assets/image/edit.svg";

// import ProfileImag from "../../../assets/image/profile.svg";
import styled from "styled-components";
import { toasty } from "../../../constants/Toast";
import { useHistory } from "react-router-dom";

function Settings() {
  const dispatch = useDispatch();
  let history = useHistory();

  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [open, setOpen] = useState();
  const [selectedDay, setSelectedDay] = useState(null);
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(() => {
    // effect
    getProfileApi();
    return () => {
      // cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfileApi = () => {
    Axios.get("/user", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        // console.log(res.data.data);
        if (res.data.successful) {
          setName(res.data.data.user.name);
          setAlias(res.data.data.user.second_name);
          setPhoneNumber(res.data.data.user.phone);
          setBirthDay(res.data.data.user.birthday);
          dispatch(addGroup(res.data.data.sub_abilities));
          setProfileImage(res.data.data.user.image);
          setPageLoading(false);
        } else {
          // console.log('error');
          // ToastEvent(res.data.message, 'error');
          // setReload(true);
        }
      })
      .catch((err) => {
        console.log(err);
        // ToastEvent('مشکلی پیش آمده', 'error');
        // setReload(true);
      });
  };

  const maxNumber = 1;
  const onChange = (imageList) => {
    // console.log("log", imageList);
    setImages(imageList);
  };

  const onSelectBirthDay = (val) => {
    setSelectedDay(val);
    setBirthDay(val.year + "/" + val.month + "/" + val.day);
    setOpen(false);
  };
  const NavigateTo = () => {
    history.push("/Ability");
  };

  const saveData = () => {
    const time = "image" + new Date().getMilliseconds();
    setBtnLoading(true);
    var body = new FormData();
    let Url = "/user";
    let bearer = `Bearer ${User.token}`;
    body.append("name", name);
    body.append("second_name", alias);
    body.append("birthday", birthDay);
    body.append("_method", "PUT");
    if (images.length !== 0) {
      body.append(
        `image`,
        dataURLtoFile(images[0]?.data_url, time)
        //  {
        //   uri: images[0]?.data_url,
        //   name: "image.jpg",
        //   type: "image/jpeg",
        // }
      );
    }

    Axios.post(Url, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        // console.log("/*/*/*", res);
        if (res.data.successful) {
          setBtnLoading(false);
          toasty("اطلاعات با موفقیت بروزرسانی شد", "success", "bottom-center");
          setEdit(!edit);
        } else {
          console.log(res.data);
          toasty("مشکلی پیش آمده", "error");
          setBtnLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toasty("مشکلی پیش آمده", "error");
        setBtnLoading(false);
      });
  };

  const dataURLtoFile = (dataUrl, filename) => {
    let arr = dataUrl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  return (
    <Content>
      {!edit && <AppEdit onClick={() => setEdit(!edit)} src={Edit} />}

      <InitialLoad loading={pageLoading}>
        <ProfileView>
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <ProfilePic
                onClick={() =>
                  imageList.length > 0 ? onImageUpdate(0) : onImageUpload()
                }
                src={imageList[0]?.data_url}
                profileImage={profileImage}
              ></ProfilePic>
            )}
          </ImageUploading>
        </ProfileView>
        <Input
          type="text"
          label="نام و نام خانوادگی"
          value={name}
          onChange={(val) => setName(val)}
          idDisabled={!edit}
        />
        <Input
          type="text"
          label="نام مستعار"
          value={alias}
          onChange={(val) => setAlias(val)}
          idDisabled={!edit}

        />
        <Input
          idDisabled={true}
          type="text"
          label="شماره موبایل"
          value={phoneNumber}
          onChange={(val) => setPhoneNumber(val)}
        />
        <ListButton
          label="تاریخ تولد"
          onClick={() => setOpen(true)}
          value={birthDay}
          idDisabled={edit}
        />
        <Title>توانمندی ها</Title>
        <ChipsArray />
        <AbilityButton onClick={() => NavigateTo()}>
          <AbilityImg />
          <Text>از اینجا میتوانید، توانمندی‌های خود را وارد کنید.</Text>
          <ArrowBtn />
        </AbilityButton>
        {edit && (
          <Button
            loading={btnLoading}
            active={true}
            text={"ثبت"}
            onPress={() => saveData()}
          />
        )}
        <BottomSheet onDismiss={() => setOpen(false)} open={open}>
          <SheetContent>
            <Calendar
              value={selectedDay}
              onChange={(val) => onSelectBirthDay(val)}
              colorPrimary="#009544"
              calendarClassName="responsive-calendar" // added this
              shouldHighlightWeekends
              locale="fa" // add this
            />
          </SheetContent>
        </BottomSheet>
      </InitialLoad>
      <ToastContainer rtl />
    </Content>
  );
}

export default Settings;

const Content = styled.div`
  border-radius: 20px;
  margin-top: 50px;
  padding-top: 35px;
  padding-bottom: 15px;
  flex-direction: column;
  display: flex;
  height: fit-content;
  width: 100%;
  background-color: #fff;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

const SheetContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Vista;
`;

const ProfilePic = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 45px;
  border: 2px solid #009544;
  background-color: whitesmoke;
  margin: 5px;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${(props) =>
    props.src
      ? `
      background-image: url(${(props) => props.src});
    `
      : `
    background-image : url(${props.profileImage})
    `}
`;

const ProfileView = styled.div`
  position: absolute;
  top: 10px;
`;

const Title = styled.div`
  display: flex;
  width: 90%;
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  color: #6a6a6a;
  text-align: justify;
  direction: rtl;
`;

const AbilityButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 90%;
  height: 100px;
  left: 43px;
  top: 444px;
  background: #fcfcfc;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 20px;
`;
const Text = styled.div`
  width: 50%;
  /* height: 39px; */
  direction: rtl;
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 17px;
  text-align: center;

  color: #6a6a6a;
`;

const AppEdit = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  left: 15px;
`;
