import "./Toast";

import { Slide, toast, } from "react-toastify";

export const toasty = (message, type, position = "top-center") => {
  switch (type) {
    case "error":
      toast.error(message, {
        position: position,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide
     
      });
      break;
    case "success":
      toast.success(message, {
        position: position,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide
     
      });
      break;

    default:
      break;
  }
};
