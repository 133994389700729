import React, { useEffect, useState } from "react";

// import { ReactComponent as AbilityImg } from "../../../assets/image/Ability.svg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ReactComponent as ArrowBtn } from "../../../assets/image/arrowBtn.svg";
import Aside from "../../Styles";
import Axios from "axios";
// import HomeSearch from "../../../components/HomeSearch";
import InitialLoad from '../../../components/Loading'
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AbilityPage() {
  let history = useHistory();
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  // const [searchValue, setSearchValue] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [catData, setCatData] = useState([]);
  
  const onBack = () => {
    history.goBack();
  };

  const NavigateTo = (item) => {
    history.push("/SubAbility", {
      id : item.id , 
      title: item.title,
    });
  };

  useEffect(() => {
    // effect
    initialize();
    return () => {
      // cleanup
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = () => {
    //
    Axios.get("/abilities", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
    })
      .then((res) => {
        if (res.data.successful) {
          setPageLoading(false);
          // console.log(res.data.data);
          setCatData(res.data.data);
        } else {
          console.log("error");
          // ToastEvent(res.data.message , error)
          setPageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // ToastEvent('مشکلی پیش آمده' , error)
        setPageLoading(false);
      });
  };

  return (
    <Aside>
      <Header>
        <div>
          <BackBtn onClick={() => onBack()}>
            <ArrowBackIosIcon fontSize="small" />
          </BackBtn>
          <HeaderTitle>توانمندی‌ها</HeaderTitle>
        </div>
      </Header>
      <InitialLoad loading={pageLoading}>
        <Main>
          {/* <HomeSearch
            type="none"
            label={"ﺟﺴﺖ‌و‌ﺟﻮ در توانمندی‌ها"}
            value={searchValue}
            onChange={(val) => setSearchValue(val)}
          /> */}
          <Title>لطفا توانمندی خود را از میان گزینه‌ها انتخاب کنید.</Title>
          <Content>
            {catData.map((item, index) => (
              <AbilityButton key={index} onClick={() => NavigateTo(item)}>
                <AbilityImg src={item.image} />
                <Text>{item.title}</Text>
                <ArrowBtn />
              </AbilityButton>
            ))}
          </Content>
        </Main>
      </InitialLoad>
    </Aside>
  );
}

const Header = styled.div`
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;
  /* identical to box height */
  text-align: center;
  color: #009544;
`;

const BackBtn = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  align-items: center;
  justify-content: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* margin-top: 56px; */
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;

const Title = styled.div`
  direction: rtl;
  width: 85%;
  height: 20px;
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  margin-top: 15px;
  color: #6a6a6a;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const AbilityButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 90%;
  height: 100px;
  left: 43px;
  top: 444px;
  background: #fcfcfc;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 5px;
`;

const Text = styled.div`
  width: 50%;
  /* height: 39px; */
  direction: rtl;
  font-family: Vista;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 17px;
  text-align: center;

  color: #6a6a6a;
`;

const AbilityImg = styled.div`
  width: 75px;
  height: 75px;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
