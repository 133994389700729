import "./main.css";

import { Input, MessageList } from "react-chat-elements";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Aside from "../../Styles";
import Axios from "axios";
import BadgeAvatars from "../../../components/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ImageUploading from "react-images-uploading";
import InitialLoad from "../../../components/Loading";
import MicIcon from "@material-ui/icons/Mic";
import Pusher from "pusher-js";
import SendIcon from "@material-ui/icons/Send";
import styled from "styled-components";
// import useRecorder from "../../../constants/UseRecorder";
import { useSelector } from "react-redux";

// Pusher.logToConsole = true;

export default function AllHistory() {
  const User = useSelector((state) => state.User);
  const bearer = `Bearer ${User.token}`;
  const [pageLoading, setPageLoading] = React.useState(true)
  // let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();
  const [images, setImages] = React.useState([]);
  const maxNumber = 10;
  const textInput = React.useRef(null);
  const chatContainer = React.useRef(null);
  const InputViewRef = React.useRef(null);
  let history = useHistory();
  let location = useLocation();
  const data = location.state.data;

  useEffect(() => {
    // scrollToMyRef();
    return () => {};
  }, []);

  const [text, setText] = React.useState("");
  const onBack = () => {
    history.goBack();
  };

  const [chat, setChat] = React.useState([]);

  const SendText = () => {
    const t = new Date();
    setChat((prev) => [
      ...prev,
      {
        position: "right",
        type: "text",
        text: text,
        date: new Date(),
        dateString:
          ("0" + t.getHours()).slice(-2) +
          ":" +
          ("0" + t.getMinutes()).slice(-2),
      },
    ]);
    onSendMessageServer("text", text);
    setText("");
    textInput.current.clear();
    scrollToMyRef();
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    // console.log("list is ", imageList);
    setImages(imageList);
    imageList.map((item) => SendPhoto(item.data_url));
  };

  const SendPhoto = async (item) => {
    const time = "image" + new Date().getMilliseconds();
    const t = new Date();
    setChat((prev) => [
      ...prev,
      {
        position: "right",
        type: "photo",
        date: t,
        dateString:
          ("0" + t.getHours()).slice(-2) +
          ":" +
          ("0" + t.getMinutes()).slice(-2),
        data: {
          uri: item,
          status: {
            click: true,
            loading: 0,
          },
        },
      },
    ]);

    setText("");
    textInput.current.clear();
    let myImage = dataURLtoFile(item, time);
    // console.log(myImage);
    onSendMessageServer("image", myImage);
    scrollToMyRef();
  };

  const scrollToMyRef = () => {
    console.log(chatContainer.current.clientHeight);
    const scroll =
      chatContainer.current.scrollHeight -
      chatContainer.current.clientHeight +
      InputViewRef.current.clientHeight +
      80;
    chatContainer.current.scrollTo(0, scroll);
    // chatContainer.current.scrollIntoView({ behavior: "smooth" })
  };

  // !audio recorder
  // const [audioURL, setAudioURL] = React.useState("");
  const [isRecording, setIsRecording] = React.useState(false);
  const [recorder, setRecorder] = React.useState(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = async (e) => {
      // setAudioURL(URL.createObjectURL(e.data));
      // setAudioURL(e.data);
      // console.log("e===>", e.data);

      var reader = new FileReader();
      reader.onloadend = function () {
        var base64data = reader.result;
        let f = dataURLtoFile(base64data, new Date());
        // console.log("f====>", f);
        onSendMessageServer("audio", f);
        sendVoice(URL.createObjectURL(e.data));
      };
      await reader.readAsDataURL(e.data);
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return new MediaRecorder(stream);
  }

  //!==================================================

  const HandelRecord = async () => {
    if (isRecording) {
      stopRecording();
      // console.log("audio is => ", audioURL);
    } else {
      startRecording();
    }
  };

  const dataURLtoFile = (dataUrl, filename) => {
    let arr = dataUrl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const sendVoice = (v) => {
    const t = new Date();
    setChat((prev) => [
      ...prev,
      {
        position: "right",
        type: "audio",
        date: new Date(),
        dateString:
          ("0" + t.getHours()).slice(-2) +
          ":" +
          ("0" + t.getMinutes()).slice(-2),
        data: {
          audioURL: v,
          status: {
            click: true,
            loading: 0,
          },
        },
      },
    ]);

    // onSendMessageServer("audio", v);
    scrollToMyRef();
  };

  // pusher config
  var pusher = new Pusher("4215284dadcbbf23d798", {
    auth: {
      headers: {
        Accept: "application/json",
        Authorization: bearer,
      },
    },
    cluster: "ap2",
    activityTimeout: [60000],
    forceTLS: true,
    encrypted: false,
    curl_options: {
      CURLOPT_SSL_VERIFYHOST: 0,
      CURLOPT_SSL_VERIFYPEER: 0,
    },
  });

  useEffect(() => {
    loadIdentity();
    return () => {
      pusher.disconnect();
      console.log("disconnect");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadIdentity = () => {
    // console.log('----');
    LoadMessages();
    var channel = pusher.subscribe(`user.${data.id}`);

    // channel.bind('App\\Events\\NewMessage', function (data) {
    //   console.log('Pusher data received is ===>',data);
    // });

    channel.bind("App\\Events\\SendMessage", function (myData) {
      // console.log('Pusher data Send is ===>', myData.me);
      setChat((prev) => [
        ...prev,
        {
          position: myData.me.user._id !== data.id ? "right" : "left",
          type:
            myData.me.audio.length !== 0
              ? "audio"
              : myData.me.text.length === 0
              ? "photo"
              : "text",
          text: myData.me.text,
          data: {
            uri: myData.me.image,
            audioURL: myData.me.audio,
            status: {
              click: true,
              loading: 0,
            },
          },
          date: Date.parse(myData.me.time),
          dateString: new Date(),
        },
      ]);
      // setMessages((previousMessages) =>
      //   GiftedChat.append(previousMessages, data.me)
      // ),
      readMessage();
      scrollToMyRef();
    });
  };

  const readMessage = () => {
    Axios.get("/read-messages", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      params: {
        user_id: data.id,
      },
    })
      .then(function (res) {
        //console.log(res.data.conversations)
        // console.log("*/*/*", res.data);

        if (res.data.successful) {
          console.log("message is read");
        } else {
          console.log("error");
        }
      })

      .catch(function (e) {
        console.log(e);
        //  if (e.response.data.logout){
        //      that.props.navigation.navigate('Auth')
        //      alert(e.response.data.message)
        //    }
      });
  };

  const LoadMessages = () => {
    setPageLoading(true)
    // console.log("*/*-/*/", data.id);
    let bearer = `Bearer ${User.token}`;
    Axios.get("/messages", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearer,
      },
      params: {
        user_id: data.id,
      },
    })
      .then(function (res) {
        //console.log(res.data.conversations)
        // console.log("*/*/*/*/*/", res.data.data);

        if (res.data.successful) {
          setPageLoading(false)
          ReformatMessage(res.data.data);
          // setMessages(res.data.data);
        } else {
          console.log(res.data);
          setPageLoading(false)

        }
      })

      .catch(function (e) {
        console.log(e);
        setPageLoading(false)

      });
  };

  const ReformatMessage = (temp) => {
    let newTemp = [];
    temp.map(
      (item, index) =>
        (newTemp = [
          ...newTemp,
          {
            position: item.user._id !== data.id ? "right" : "left",
            type:
              item.audio.length !== 0
                ? "audio"
                : item.text.length === 0
                ? "photo"
                : "text",
            text: item.text,
            data: {
              uri: item.image,
              audioURL: item.audio,
              status: {
                click: true,
                loading: 0,
              },
            },
            date: Date.parse(item.time),
            dateString: item.createdAt,
          },
        ])
    );
    setChat(newTemp.reverse());
    scrollToMyRef();
    // console.log(newTemp);
  };

  const onSendMessageServer = (type, param) => {
    // console.log("param=>" + param);
    var body = new FormData();
    let Url = "/message/send";
    body.append("to", data.id);

    switch (type) {
      case "text":
        body.append("text", param);
        break;
      case "image":
        body.append(`image`, param);
        break;
      case "audio":
        body.append("voice", param);
        break;
      default:
        break;
    }

    // body.forEach((i) => console.log("i=====>", i));
    // setLoading(false)
    Axios.post(Url, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: bearer,
      },
    })
      .then((res) => {
        // console.log( "res")
        if (res.data.successful) {
          console.log(res.data);
          scrollToMyRef()
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Aside>
      <Header>
        <div>
          <BackBtn onClick={() => onBack()}>
            <ArrowBackIosIcon fontSize="small" />
          </BackBtn>
          <Section>
            <BadgeAvatars
              size={"small"}
              image={data.avatar ? data.avatar : data.image }
              newMessage={false}
            />
            <HeaderTitle>{data?.second_name.length > 0 ? data.second_name : data.name}</HeaderTitle>
          </Section>
        </div>
      </Header>
      <Main >
      <InitialLoad loading={pageLoading}>

        <Content ref={chatContainer}>
          <MessageList
            className="message-list"
            lockable={true}
            toBottomHeight={"100%"}
            dataSource={chat}
            onDownload={(event) => console.log(event)}
            // onClick={(event)=>console.log(event)}
          />
        </Content>
        </InitialLoad>
      </Main>
      <InputView ref={InputViewRef}>
        <Input
          ref={textInput}
          defaultValue={text}
          onChange={(e) => setText(e.target.value)}
          style={{ fontFamily: "Vista" }}
          placeholder="اینجا پیام خود را بنویسید ..."
          multiline={true}
          leftButtons={
            <div>
              <SendIcon
                style={{ padding: 5, marginLeft: 5 }}
                onClick={() => SendText()}
              />
              <MicIcon
                onClick={() => HandelRecord()}
                style={{ padding: 5, color: isRecording ? "green" : "black" }}
              />
            </div>
          }
          rightButtons={
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <ImageIcon
                  onClick={() => {
                    onImageRemoveAll();
                    onImageUpload();
                  }}
                />
              )}
            </ImageUploading>
          }
        />
      </InputView>
    </Aside>
  );
}

const Header = styled.div`
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const HeaderTitle = styled.p`
  font-family: "Vista";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 0px;
  /* identical to box height */
  text-align: center;
  color: #009544;
`;

const BackBtn = styled.div`
  display: flex;
  width: 47px;
  height: 47px;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 95%;
  flex-direction: column;
  display: flex;
  overflow: auto;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* margin-top: 48px; */
  align-items: center;
  justify-content: flex-start;
  /* padding-bottom: 45px; */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

const InputView = styled.div`
  direction: rtl;
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #009544;
  padding-bottom: 1px;
  padding-top: 1px;
  bottom: 0;
  font-family: Vista;
  -webkit-box-shadow: 5px 5px 15px -5px #000000;
  box-shadow: 5px 5px 15px -5px #000000;
`;
