import React from "react";
import styled from "styled-components";
function ListButton({ label, value, onClick, idDisabled }) {
  return (
    <Container
      onClick={() => idDisabled && onClick()}
      focused={value === "" || value.length === 0 ? false : true}
    >
      <label>{label}</label>
      <div>{value}</div>
    </Container>
  );
}

export default ListButton;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  position: relative;
  margin-top: 10px;
  width: 85%;

  & > div {
    height: ${(props) => (props.focused ? null : "48px")};
    border: 1px solid ${(props) => (props.focused ? "#009544" : "#eee")};
    border-radius: 20px;
    background-color: transparent;
    outline: none;
    padding: ${(props) => (props.focused ? " 12px 3px 12px 15px" : null)};
    font-size: 16px;
    transition: all 0.2s ease;
    /* z-index: 500; */
    direction: rtl;
    padding-right: 20px;
    font-family: Vista;
  }
  & > label {
    color: #757575;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-5px);
    right: 15px;
    transition: all 0.2s ease;
    /* z-index: 500; */
    font-family: Vista;
    ${(props) =>
      props.focused &&
      `
      font-size: 12px;
      top : -2px ;
      // z-index: 501;
      background: white;
      padding: 0 8px;
      font-family : Vista
    `}
  }
`;
