import "react-toastify/dist/ReactToastify.css";
import "../../../constants/Toast.css";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import ListButton from "../../../components/ListButton";
import React from "react";
import Switch from "../../../components/Switch";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { toasty } from "../../../constants/Toast";

function StepOne({
  onPressNext,
  title,
  province,
  city,
  ability,
  subAbility,
  emergency,
  onChangeTitle,
  showModal,
  handleChange,
  isSelect,
  loadingSub,
}) {

  const checkActive=()=>{
    if ( title.length !== 0 && province.length !== 0 && city.length !== 0 && ability.length !== 0 && subAbility.length !== 0){
      return true
    }else {
      return false
    }
  }

  const nextStep =()=>{
      if(checkActive()){
        onPressNext()
      }else {
        toasty('لطفا تمامی موارد را وارد نمایید' ,"error")
      }
  }

  return (
    <Content>
      <Title>
        لطفا اطلاعات زیر را پر کنید، تا درخواست شما در سامانه ثبت شود.
      </Title>
      <Input
        type="text"
        label="عنوان"
        value={title}
        onChange={(val) => onChangeTitle(val)}
      />
      <ListButton
        onClick={() => showModal(["province", "استان ها"])}
        label="استان"
        value={province}
      />
      <ListButton
        onClick={() => showModal(["city", "شهرها"])}
        label="شهر"
        value={city}
      />
      <ListButton
        onClick={() => showModal(["ability", "دسته‌بندی ها"])}
        label="انتخاب دسته‌بندی"
        value={ability}
      />
      <ListButton
        onClick={() => {
          if(loadingSub){
            toasty('درحال دریافت داده دسته بندی' ,"error")
          }else
          if (isSelect) {
            showModal(["subAbility", "زیرشاخه ها"]);
          }else{
            toasty('ابتدا دسته بندی را انتخاب کنید' ,"error")
          }
        }}
        label={loadingSub ? "... در حال دریافت داده": "انتخاب زیرشاخه"}
        value={subAbility}
      />
      <Section>
        <Switch
          id="test-switch"
          toggled={emergency}
          onChange={(e) => handleChange(e.target.checked)}
        />
      </Section>
      <ButtonView>
        <Button active={checkActive()} text={"ادامه"} onPress={() => nextStep()} />
      </ButtonView>
      <ToastContainer rtl />
    </Content>
  );
}

export default StepOne;
const Content = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
`;

const Title = styled.div`
  direction: rtl;
  display: flex;
  width: 90%;
  left: 65px;
  top: 89px;
  font-family: "Vista";
  font-size: 14px;
  text-align: center;
  color: #828282;
`;

const Section = styled.div`
  width: 85%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

const ButtonView = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
