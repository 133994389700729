import { isLogin, token } from "../store/actions/user_action";

import Axios from "axios";
import CustomButton from "../../components/Button";
import Input from "../../components/Input";
import React from "react";
import { ToastContainer } from "react-toastify";
import myImage from "../../assets/image/logo.png";
import styled from "styled-components";
import { toasty } from "../../constants/Toast";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Warper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 203px;
`;

export default function Login() {
  const dispatch = useDispatch();
  let history = useHistory();

  const [value, setValue] = React.useState("");
  const [onError, setOnError] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false)
  // const onPressNext = () => {
  //   // history.push('/Otp', {
  //   //   phone: value,
  //   //   from : 'details',
  //   //   foo: 'bar'
  //   // })
  // };

  const verification = () => {
    setBtnLoading(true)
    let data = JSON.stringify({
      phone: value,
    });
    Axios.post("/verify", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.successful) {
          if (res.data.data.flag) {
            dispatch(isLogin());
            dispatch(token(res.data.data.token));
            history.push("/HomePage", {
              phone: value,
              from: "details",
              foo: "bar",
            });
          } else {
            setBtnLoading(true)
            history.push("/Otp", {
              phone: value,
              from: "details",
              foo: "bar",
            });
          }
        } else {
          toasty(res.data.message , 'error');
          setBtnLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // toast("خطا ، دسترسی به اینترنت را بررسی نمایید");
        setBtnLoading(false);
      });
  };

  return (
    <Warper>
      <Logo src={myImage} />
      <Input
        maxLength={11}
        type="phoneNumber"
        label="شماره موبایل"
        value={value}
        onChange={(val) => setValue(val)}
        onError={(val) => setOnError(val)}
        logError={() => console.log(onError)}
      />
      <CustomButton
      loading={btnLoading}
        active={onError}
        text={"ادامه"}
        onPress={() => verification()}
      />
      <ToastContainer rtl />

    </Warper>
  );
}
